<template>
  <v-container
    class="py-5 px-0"
  >
    <v-row>
      <v-col
        class="pr-2"
        cols="8"
      >
        <div
          class="d-flex flex-column elevation-3 pa-5"
        >
          <v-row>
            <v-col>
              <span class="text-uppercase font-weight-medium">Perímetros Corporais (em centímetros)</span>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.pescoco"
                class="dense-padding-input-slot"
                label="Pescoço"
                @focus="perimetyActive = 'pescoco'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.deltoides"
                class="dense-padding-input-slot"
                label="Deltóides"
                @focus="perimetyActive = 'deltoides'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.braco_direito_relaxado"
                class="dense-padding-input-slot"
                label="Braço Dir. Relaxado"
                @focus="perimetyActive = 'braco-d'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.braco_direito_contraido"
                class="dense-padding-input-slot"
                label="Braço Dir. Contraído"
                @focus="perimetyActive = 'braco-d'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.antebraco_direito"
                class="dense-padding-input-slot"
                label="Antebraço Direito"
                @focus="perimetyActive = 'antebraco-d'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.abdomen"
                class="dense-padding-input-slot"
                label="Abdomen"
                @focus="perimetyActive = 'abdomen'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.coxa_medial_direita"
                class="dense-padding-input-slot"
                label="Coxa Medial Direita"
                @focus="perimetyActive = 'coxa-media-d'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.coxa_distral_direita"
                class="dense-padding-input-slot"
                label="Coxa Distal Direita"
                @focus="perimetyActive = 'coxa-distal-d'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.panturrilha_direita"
                class="dense-padding-input-slot"
                label="Panturrilha Direita"
                @focus="perimetyActive = 'panturrilha-d'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
            </v-col>
            <v-col
              class="text-center"
            >
              <img
                height="562"
                :src="getImageperimeter()"
              >
            </v-col>
            <v-col>

              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.torax"
                class="dense-padding-input-slot"
                label="Tórax"
                @focus="perimetyActive = 'torax'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.braco_esquerdo_relaxado"
                class="dense-padding-input-slot"
                label="Braço Esq. Relaxado"
                @focus="perimetyActive = 'braco-e'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.braco_esquerdo_contraido"
                class="dense-padding-input-slot"
                label="Braço Esq. Contraído"
                @focus="perimetyActive = 'braco-e'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.cintura"
                class="dense-padding-input-slot"
                label="Cintura"
                @focus="perimetyActive = 'cintura'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense

              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.antebraco_esquerdo"
                class="dense-padding-input-slot"
                label="Antebraço Esquerdo"
                @focus="perimetyActive = 'antebraco-e'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.quadril"
                class="dense-padding-input-slot"
                label="Quadril"
                @focus="perimetyActive = 'quadril'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.coxa_medial_esquerda"
                class="dense-padding-input-slot"
                label="Coxa Medial Esquerda"
                @focus="perimetyActive = 'coxa-media-e'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.coxa_distal_esquerda"
                class="dense-padding-input-slot"
                label="Coxa Distal Esquerda"
                @focus="perimetyActive = 'coxa-distal-e'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.bodyPerimeter.panturrilha_esquerda"
                class="dense-padding-input-slot"
                label="Panturrilha Esquerda"
                @focus="perimetyActive = 'panturrilha-e'"
                @blur="perimetyActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="cm"
                dense
                v-if="!getMarinha()"
              />

            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        class="pl-2"
        cols="4"
      >

        <IMC v-if="cycleToBeEvaluated"/>
        <cintura-quadril v-if="cycleToBeEvaluated && !getMarinha()"   />
        <cintura-estatura v-if="cycleToBeEvaluated && !getMarinha()"   />
        <somatorio    />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pr-2"
        cols="8" v-if="!getMarinha()"
      >
        <div
          class="d-flex flex-column elevation-3 pa-5"
        >
          <v-row>
            <v-col>
              <span class="text-uppercase font-weight-medium">Dobras Cutâneas (em milímetros)</span>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>

              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.peitoral"
                class="dense-padding-input-slot"
                label="Peitoral"
                @focus="skinFoldActive = 'peitoral'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
                v-if="!isStudent"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.biceps"
                class="dense-padding-input-slot"
                label="Bíceps"
                @focus="skinFoldActive = 'biceps'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
                v-if="!isStudent"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.axilar_media"
                class="dense-padding-input-slot"
                label="Axilar Média"
                @focus="skinFoldActive = 'axilar'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
                v-if="!isStudent"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.supra_iliaca"
                class="dense-padding-input-slot"
                label="Supra Ilíaca"
                @focus="skinFoldActive = 'supra-iliaca'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
                v-if="!isStudent"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.abdominal"
                class="dense-padding-input-slot"
                label="Abdominal"
                @focus="skinFoldActive = 'abdominal';"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.supraespinhal"
                class="dense-padding-input-slot"
                label="Supraespinhal"
                @focus="skinFoldActive = 'supraespinhal'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
                v-if="!isStudent"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.coxa_media"
                class="dense-padding-input-slot"
                label="Coxa Média"
                @focus="skinFoldActive = 'coxa-media'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
                v-if="!isStudent"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.panturrilha"
                class="dense-padding-input-slot"
                label="Panturrilha"
                @focus="skinFoldActive = 'panturrilha'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                filled suffix="mm"
                dense
                v-if="!isStudent"
              />
            </v-col>
            <v-col
              class="text-center"
            >
              <img
                height="562"
                :src="getImageSkinFold()"
              >
            </v-col>
            <v-col v-if="!isStudent">
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.subescapular"
                class="dense-padding-input-slot"
                filled suffix="mm"
                @focus="skinFoldActive = 'subescapular'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                dense
                label="Subescapular"
              />
              <v-text-field
                v-model="cycleToBeEvaluated.evaluated_items.perimeter.skinFold.triceps"
                class="dense-padding-input-slot"
                filled suffix="mm"
                @focus="skinFoldActive = 'triceps'"
                @blur="skinFoldActive = null;cycle === 'micro' ? $emit('updateMicrocycleEvaluation') : $emit('updateMesocycleEvaluation');"
                :readonly="disableInput"
                dense
                label="Tríceps"
              />
            </v-col>
          </v-row>

        </div>
      </v-col>
      <v-col
        class="pl-2"
        cols="4"
      >

      </v-col>
    </v-row>

    <v-row v-if="!readonly">
      <v-col class="d-flex justify-end">
        <v-btn
          text
          color="secondary"
          @click="$emit('closeDialog')"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="ml-10"
          color="primary"
          @click="cycle === 'micro' ? $emit('updateMicrocycleEvaluation', 'close') : $emit('updateMesocycleEvaluation', 'close')"
        >
          Salvar Avaliação
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
import IMC from './perimetria/IMC'
import CinturaQuadril from './perimetria/CinturaQuadril'
import CinturaEstatura from './perimetria/CinturaEstatura'
import Somatorio from './perimetria/Somatorio'

export default {
  name: 'perimetria',
  components: {
    IMC,
    CinturaQuadril,
    CinturaEstatura,
    Somatorio
  },
  props: {
    readonly: {
      default: false,
      type: Boolean
    },
    isStudent: {
      default: false,
      type: Boolean
    },
    cycle: {
      type: String,
      default: 'meso'
    }
  },
  data () {
    return {
      createOrUpdateMesoDialog: false,
      mesoCycleDetailsDialog: true,
      microCycleDetailsDialog: true,
      skinFoldActive: null,
      skinFold: null,
      perimetyActive: null,
      cycleToBeEvaluated: {
        macrocycle: {
          skinfold_protocol: ''
        },
        evaluated_items: {
          perimeter: {
            skinFold: {
              triceps: '',
              peitoral: '',
              biceps: '',
              axilar_media: '',
              supra_iliaca: '',
              abdominal: '',
              supraespinhal: '',
              coxa_media: '',
              panturrilha: '',
              subescapular: ''
            },
            bodyPerimeter: {
              pescoco: '',
              deltoides: '',
              braco_direito_relaxado: '',
              braco_direito_contraido: '',
              antebraco_direito: '',
              abdomen: '',
              coxa_medial_direita: '',
              coxa_distral_direita: '',
              panturrilha_direita: '',
              torax: '',
              braco_esquerdo_relaxado: '',
              braco_esquerdo_contraido: '',
              cintura: '',
              antebraco_esquerdo: '',
              quadril: '',
              coxa_medial_esquerda: '',
              coxa_distal_esquerda: '',
              panturrilha_esquerda: ''
            }
          }
        }
      }
    }
  },
  methods: {
    getImageSkinFold () {
      if (!this.skinFoldActive) {
        return require('@/assets/images/dobras/empty.png')
      }
      return require(`@/assets/images/dobras/${this.skinFoldActive}.png`)
    },
    getImageperimeter () {
      if (!this.perimetyActive) {
        return require('@/assets/images/perimetros/empty.png')
      }
      return require(`@/assets/images/perimetros/${this.perimetyActive}.png`)
    },
    getMarinha () {
      if (this.isStudent) {
        return true
      }
      if (this.cycleToBeEvaluated.macrocycle.skinfold_protocol === 'Marinha Americana') {
        return true
      }
      return false
    },
    setCycle () {
      this.cycleToBeEvaluated = this.cycle === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated']),
    disableInput () {
      return this.readonly || this.isStudent
    }
  },
  mounted () {
    this.setCycle()
  }
}
</script>

<style scoped>
</style>
