import Vue from 'vue'
import VueRouter from 'vue-router'

// Layouts
import AdminHomeLayout from '../layouts/AdminHomeLayout.vue'
import HomeLayout from '../layouts/HomeLayout.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'

// Views
import AdminLogin from '../views/admin/authentication/AdminLogin.vue'
import AdminResetPassword from '../views/admin/authentication/AdminResetPassword.vue'
import PersonalList from '../views/admin/personal/PersonalList.vue'
import PersonalShow from '../views/admin/personal/PersonalShow.vue'
import MuscleGroupList from '@/views/admin/configurations/muscleGroups/MuscleGroupList'
import MuscleGroupDetail from '@/views/admin/configurations/muscleGroups/MuscleGroupDetail'
import MuscleGroupNew from '@/views/admin/configurations/muscleGroups/MuscleGroupCreate'
import PlansList from '@/views/admin/configurations/plans/PlansList'
import PlansNew from '@/views/admin/configurations/plans/PlansNew'
import TermsView from '@/views/admin/configurations/terms/TermsView'
import LandingPageEdit from '@/views/admin/configurations/LandingPage/LandingPageEdit'

import PersonalDashboard from '../views/admin/personal/PersonalDashboard.vue'

import Login from '../views/authentication/Login.vue'
import ConfirmRegister from '../views/authentication/ConfirmRegister.vue'
import ConfirmCodeStudent from '../views/authentication/ConfirmCodeStudent.vue'
import ResetPassword from '../views/authentication/ResetPassword.vue'
import RenewPlan from '../views/authentication/RenewPlan'
import ConfirmStudentAccount from '../views/authentication/ConfirmStudentAccount.vue'

import StudentList from '../views/students/StudentList.vue'
import StudentShow from '../views/students/StudentShow.vue'
import StudentShowMesociclo from '../views/students/StudentShowMesociclo.vue'
import StudentDashboard from '../views/students/StudentDashboard.vue'

import ExercisesAdminList from '../views/admin/configurations/exercises/List.vue'
import TutorialAdminList from '../views/admin/configurations/tutorial/List.vue'
import ExercisesPersonalList from '../views/personal/configurations/exercises/List.vue'
import TutorialPersonalList from '../views/personal/configurations/tutorial/List.vue'
import TutorialStudentList from '../views/students/tutorial/List.vue'
import PersonalProfile from '@/views/personal/configurations/PersonalProfile.vue'
import PersonalProfileForm from '@/views/personal/configurations/PersonalProfileForm.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'home',
    redirect: { name: 'Login' }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: DefaultLayout,
    redirect: { name: 'AdminLogin' },
    children: [
      {
        path: 'autenticacao',
        component: DefaultLayout,
        children: [
          {
            path: 'entrar',
            name: 'AdminLogin',
            component: AdminLogin
          },
          {
            path: 'resetar_senha',
            name: 'AdminResetPassword',
            component: AdminResetPassword
          }
        ]
      },
      {
        path: 'home',
        name: 'AdminHomeLayout',
        component: AdminHomeLayout,
        redirect: { name: 'PersonalDashboard' },
        children: [
          {
            path: 'personal',
            name: 'Personal',
            component: DefaultLayout,
            redirect: { name: 'PersonalDashboard' },
            children: [
              {
                path: 'dashboard',
                name: 'PersonalDashboard',
                component: PersonalDashboard
              },
              {
                path: 'listar',
                name: 'PersonalList',
                component: PersonalList
              },
              {
                path: 'visualizar/:id',
                name: 'PersonalShow',
                component: PersonalShow
              },
              {
                path: 'planos',
                name: 'Plans',
                component: DefaultLayout,
                redirect: { name: 'Plans' },
                children: [
                  {
                    path: '',
                    name: 'PlansList',
                    component: PlansList
                  },
                  {
                    path: 'form',
                    name: 'PlansNew',
                    component: PlansNew,
                    props: true
                  }

                ]
              },
              {
                path: 'LandingPage',
                name: 'LandingPage',
                component: DefaultLayout,
                redirect: { name: 'LandingPage' },
                children: [
                  {
                    path: '',
                    name: 'LandingPageEdit',
                    component: LandingPageEdit
                  }
                ]
              }
            ]
          }

        ]
      },
      {
        path: 'configuracoes',
        name: 'ConfigurationList',
        component: AdminHomeLayout,
        redirect: { name: 'ExercisesAdminList' },
        children: [
          {
            path: 'exercicios',
            name: 'Exercises',
            component: DefaultLayout,
            redirect: { name: 'ExercisesAdminList' },
            children: [
              {
                path: '',
                name: 'ExercisesAdminList',
                component: ExercisesAdminList
              }
            ]
          },
          {
            path: 'tutoriais',
            name: 'Tutorials',
            component: DefaultLayout,
            redirect: { name: 'TutorialAdminList' },
            children: [
              {
                path: '',
                name: 'TutorialAdminList',
                component: TutorialAdminList
              }
            ]
          },
          {
            path: 'grupos_musculares',
            name: 'MuscleGroup',
            component: DefaultLayout,
            redirect: { name: 'MuscleGroupList' },
            children: [
              {
                path: '',
                name: 'MuscleGroupList',
                component: MuscleGroupList
              },
              {
                path: 'form',
                name: 'MuscleGroupNew',
                component: MuscleGroupNew
              },
              {
                path: 'detalhe/:id',
                props: true,
                name: 'MuscleGroupDetail',
                component: MuscleGroupDetail
              }
            ]
          },
          {
            path: 'termos',
            name: 'Terms',
            component: DefaultLayout,
            redirect: { name: 'Terms' },
            children: [
              {
                path: '',
                name: 'TermsView',
                component: TermsView
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/autenticacao',
    name: 'AuthenticationLayout',
    component: DefaultLayout,
    redirect: { name: 'Login' },
    children: [
      {
        path: 'entrar',
        name: 'Login',
        component: Login
      },
      {
        path: 'confirmar_registro',
        name: 'ConfirmRegister',
        component: ConfirmRegister
      },
      {
        path: 'confirmar_registro_aluno',
        name: 'ConfirmCodeStudent',
        component: ConfirmCodeStudent
      },
      {
        path: 'resetar_senha',
        name: 'ResetPassword',
        component: ResetPassword
      },
      {
        path: 'renovar_plano',
        name: 'RenewPlan',
        component: RenewPlan
      },
      {
        path: 'aluno/confirmar_conta',
        name: 'ConfirmStudentAccount',
        component: ConfirmStudentAccount
      }
    ]
  },
  {
    path: '/inicio',
    name: 'HomeLayout',
    component: HomeLayout,
    redirect: { name: 'Students' },
    children: [
      {
        path: 'alunos',
        name: 'Students',
        component: DefaultLayout,
        redirect: () => {
          const user = JSON.parse(localStorage.getItem('user'))

          if (user && user.role_type === 'Student') {
            return { path: '/alunos/:id', params: { id: user.role_id } }
          } else {
            return { name: 'StudentDashboard' }
          }
        },
        children: [
          {
            path: 'LandingPage',
            name: 'LandingPage',
            component: DefaultLayout,
            redirect: { name: 'LandingPage' },
            children: [
              {
                path: '',
                name: 'LandingPagePersonalEdit',
                component: LandingPageEdit
              }
            ]
          },
          {
            path: 'planos',
            name: 'Plans',
            component: DefaultLayout,
            redirect: { name: 'Plans' },
            children: [
              {
                path: '',
                name: 'PlansPersonalList',
                component: PlansList
              },
              {
                path: 'form',
                name: 'PlansPersonalNew',
                component: PlansNew,
                props: true
              }

            ]
          },
          {
            path: 'dashboard',
            name: 'StudentDashboard',
            component: StudentDashboard
          },
          {
            path: 'tutorial',
            name: 'TutorialStudentList',
            component: TutorialStudentList,
            meta: { scope: ['customer'] }
          },
          {
            path: 'listar',
            name: 'StudentList',
            component: StudentList,
            meta: { scope: ['personal'] }
          },
          {
            path: 'visualizar/:id',
            name: 'StudentShow',
            component: StudentShow,
            alias: '/alunos/:id',
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(localStorage.getItem('user'))
              if (user.role_type === 'Student') {
                const roleId = user.role_id + ''
                if (roleId !== to.params.id) {
                  next({ name: 'Students' })
                }
              }
              next()
            },
            meta: { scope: ['personal', 'customer'] }
          },
          {
            path: 'visualizar/:id/macrociclo/:idMacrociclo/mesociclo/:idMesociclo',
            name: 'StudentShowMesociclo',
            component: StudentShowMesociclo,
            alias: '/alunos/:id/macrociclo/:idMacrociclo/mesociclo/:idMesociclo',
            beforeEnter: (to, from, next) => {
              const user = JSON.parse(localStorage.getItem('user'))
              if (user.role_type === 'Student') {
                const roleId = user.role_id + ''
                if (roleId !== to.params.id) {
                  next({ name: 'Students' })
                }
              }
              next()
            },
            meta: { scope: ['personal', 'customer'] }
          }
        ]
      }
    ]
  },
  {
    path: '/configuracoes',
    name: 'ConfigurationListPersonal',
    component: HomeLayout,
    redirect: { name: 'StudentList' },
    children: [
      {
        path: 'exercicios',
        name: 'ExercisesPersonalList',
        component: ExercisesPersonalList,
        meta: { scope: ['personal'] }
      },
      {
        path: 'alunos/:my',
        name: 'StudentsPersonal',
        component: DefaultLayout
      },
      {
        path: 'tutorial',
        name: 'TutorialPersonalList',
        component: TutorialPersonalList,
        meta: { scope: ['personal'] }
      },
      {
        path: 'perfil',
        name: 'PersonalProfile',
        component: PersonalProfile,
        meta: { scope: ['personal'] }
      },
      {
        path: 'perfil/form',
        name: 'PersonalProfileForm',
        component: PersonalProfileForm,
        meta: { scope: ['personal'] }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['Login', 'AdminLogin', 'ResetPassword', 'AdminResetPassword', 'ConfirmRegister', 'ConfirmCodeStudent', 'ConfirmStudentAccount', 'RenewPlan']
  const disabledRoutes = ['PlansPersonalList', 'PlansPersonalNew']
  const authRequired = !publicPages.includes(to.name)
  const loggedIn = localStorage.getItem('access_token')

  if (disabledRoutes.includes(to.name)) {
    return next(false)
  }

  if (authRequired && !loggedIn) {
    return next({ name: 'Login' })
  }

  next()
})

export default router
