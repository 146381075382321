<template>
  <v-card class="mb-6">
    <v-card-title>
      <span class="secondary--text subtitle-2">{{ title }}</span>
    </v-card-title>
    <v-divider/>
    <v-data-table
      no-data-text="Nenhum exercício cadastrado"
      disable-pagination
      hide-default-footer
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="uniqueId"
      :headers="tableHeaders"
      :items="list"
    >
      <template v-slot:body="{items, isExpanded}">
        <draggable
          v-model="list"
          tag="tbody"
          handle=".drag"
          ghost-class="ghost"
          @end="updatePosition"
        >
          <tr v-for="(item) in items" :key="item.uniqueId">
            <td :colspan="headers.length" class="pa-0 ma-0">
              <data-table-row-handler
                :item="item"
                :headers="headers"
                :isExpanded="isExpanded(item)"
                :canDrag="canDrag"
                :readonly="readonly"
              >
                <template v-slot:item.exercise.name="{ item }">
                  {{ getExerciseName(item) }}
                </template>
                <template v-slot:item.min_reps="{ item }">
                  {{ getMinReps(item) }}
                </template>
                <template v-slot:item.max_reps="{ item }">
                  {{ getMaxReps(item) }}
                </template>
                <template v-slot:item.muscleGroups="{ item }">
                  {{ getMuscleGroups(item) }}
                </template>
                <template v-slot:item.exercise_technic.name="{ item }">
                  {{ getTechniqueName(item) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      class="mr-2"
                      text
                      icon
                      x-small
                      color="secondary"
                      v-if="!readonly"
                      @click="newExerciseDialog(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    text
                    icon
                    x-small
                    color="secondary"
                    v-if="!readonly"
                    @click="destroyExerciseDialog(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    text
                    icon
                    x-small
                    color="secondary"
                    v-if="!readonly"
                    @click="copyExerciseDialog(item)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    text
                    icon
                    x-small
                    color="secondary"
                    v-if="item.exercise_technic"
                    @click.stop="openTechniqueHint(item)"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    icon
                    x-small
                    color="secondary"
                    v-if="item.observacao"
                    @click.stop="expandRow(item)"
                  >
                    <v-icon>{{ expandIcon(item) }}</v-icon>
                  </v-btn>
                </template>
                <template v-slot:expanded-item v-if="item.observacao">
                  <td :colspan="headers.length" class="observations grey lighten-4 grey--text text--darken-2">
                    Observações: {{ item.observacao }}
                  </td>
                </template>
              </data-table-row-handler>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>

    <v-dialog v-model="techniqueHintDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ techniqueHintTitle }}</v-card-title>
        <v-card-text>{{ techniqueHintDescription }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="techniqueHintDialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import DataTableRowHandler from './DataTableRowHandler.vue'
import exerciseTechnicsService from '@/services/user-exercise-technics-service' // Importar o serviço
import microcycleService from '@/services/microcycle-service'

export default {
  name: 'student-training-table-micro',
  components: {
    draggable,
    DataTableRowHandler
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    trainingIndex: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: [],
      exercises: [...this.items],
      list: this.items.map((item, index) => ({
        ...item,
        order: index + 1,
        uniqueId: this.generateUniqueId(item, index)
      })),
      cycleToBeEvaluated: {
        trainings: []
      },
      techniqueHintDialog: false,
      techniqueHintTitle: '',
      techniqueHintDescription: ''
    }
  },
  methods: {
    ...mapActions('base', ['setLoader']),
    ...mapActions('students', ['setTrainingIndex', 'setCreateOrUpdateExerciseDialog', 'setCreateOrUpdateExercise', 'setCreateOrUpdateExerciseIndex']),
    generateUniqueId (item, index) {
      if (Array.isArray(item.exercise)) {
        return `array-${item.exercise.map(ex => ex.id).join('-')}-${index}`
      }
      return `single-${item.exercise?.id || 'no-id'}-${index}`
    },
    getExerciseName (item) {
      if (Array.isArray(item.exercise)) {
        return item.exercise.map(ex => ex.name || 'Sem nome').join(' / ')
      }
      return item.exercise?.name || 'Sem nome'
    },
    getMinReps (item) {
      if (Array.isArray(item.exercise)) {
        return item.exercise.map(ex => ex.min_reps || '-').join(' / ')
      }
      return item.exercise?.min_reps || item.min_reps || '-'
    },
    getMaxReps (item) {
      if (Array.isArray(item.exercise)) {
        return item.exercise.map(ex => ex.max_reps || '-').join(' / ')
      }
      return item.exercise?.max_reps || item.max_reps || '-'
    },
    getMuscleGroups (item) {
      if (Array.isArray(item.exercise)) {
        return item.exercise
          .flatMap(ex => ex.muscle_group || [])
          .map(mg => mg.name)
          .filter((name, idx, self) => self.indexOf(name) === idx)
          .join(', ') || '-'
      }
      return (item.exercise?.muscle_group || []).map(mg => mg.name).join(', ') || '-'
    },
    getTechniqueName (item) {
      return item.exercise_technic?.name || '-'
    },
    async openTechniqueHint (item) {
      if (!item.exercise_technic || !item.exercise_technic.name) {
        this.techniqueHintTitle = 'Técnica'
        this.techniqueHintDescription = 'Sem descrição disponível'
        this.techniqueHintDialog = true
        return
      }

      this.setLoader({ show: true })
      try {
        const response = await exerciseTechnicsService.all({ q: item.exercise_technic.name })
        const techniques = response.data // Array retornado pela API
        const technique = techniques.exercise_technics[0]
        this.techniqueHintTitle = technique?.name || item.exercise_technic.name
        this.techniqueHintDescription = technique?.description || 'Sem descrição disponível'
      } catch (error) {
        console.error('Erro ao buscar técnica:', error)
        this.techniqueHintTitle = item.exercise_technic.name
        this.techniqueHintDescription = 'Descrição não encontrada!'
      } finally {
        this.setLoader({ show: false })
        this.techniqueHintDialog = true
      }
    },
    newExerciseDialog (exercise) {
      this.setTrainingIndex(this.trainingIndex)
      this.setCreateOrUpdateExercise(exercise)
      const exerciseIndex = this.microcycleToBeEvaluated.trainings[this.trainingIndex].exercises.findIndex(el => {
        if (Array.isArray(el.exercise) && Array.isArray(exercise.exercise)) {
          return el.exercise.every((ex, idx) => ex.id === exercise.exercise[idx]?.id)
        } else if (!Array.isArray(el.exercise) && !Array.isArray(exercise.exercise)) {
          return el.exercise.id === exercise.exercise.id
        }
        return false
      })
      this.setCreateOrUpdateExerciseIndex(exerciseIndex)
      this.setCreateOrUpdateExerciseDialog(false)
      this.setCreateOrUpdateExerciseDialog(true)
    },
    destroyExerciseDialog (item) {
      this.$emit('destroyExerciseDialog', item)
    },
    copyExerciseDialog (item) {
      this.$emit('copyExerciseDialog', item, this.trainingIndex)
    },
    expandRow (item) {
      const index = this.expanded.findIndex(exp => exp.uniqueId === item.uniqueId)
      if (index > -1) {
        this.expanded.splice(index, 1)
      } else {
        this.expanded = [item]
      }
      this.$forceUpdate()
    },
    expandIcon (item) {
      return this.expanded.find(exp => exp.uniqueId === item.uniqueId) ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    updatePosition () {
      this.list.forEach((exercise, index) => {
        exercise.order = index + 1
      })
      this.exercises = [...this.list]
      const updatedExercises = this.microcycleToBeEvaluated.trainings[this.trainingIndex].exercises.map(exercise => {
        const exerciseIds = new Set(
          Array.isArray(exercise.exercise)
            ? exercise.exercise.map(ex => ex.id)
            : [exercise.exercise.id]
        )

        const updatedExercise = this.list.find(e =>
          e.sq === exercise.sq &&
          (Array.isArray(e.exercise)
            ? e.exercise.some(ex => exerciseIds.has(ex.id)) // Uso de Set para eficiência
            : exerciseIds.has(e.exercise.id)
          )
        )
        return updatedExercise ? { ...updatedExercise, order: updatedExercise.order } : exercise
      })

      updatedExercises.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity))

      this.$set(this.microcycleToBeEvaluated.trainings[this.trainingIndex], 'exercises', updatedExercises)

      this.setLoader({ show: true })
      const payload = { ...this.microcycleToBeEvaluated }
      microcycleService
        .createOrUpdate(this.$route.params.id, this.$route.params.idMacrociclo, this.$route.params.idMesociclo, payload)
        .finally(() => this.setLoader({ show: false }))
    }
  },
  computed: {
    ...mapGetters('students', ['microcycleToBeEvaluated']),
    tableHeaders () {
      return this.readonly ? this.headers.slice(1, this.headers.length) : this.headers
    },
    canDrag () {
      return this.items.length > 1
    }
  },
  watch: {
    items (newVal) {
      this.expanded = []
      this.exercises = [...newVal]
      this.list = newVal.map((item, index) => ({
        ...item,
        order: index + 1,
        uniqueId: this.generateUniqueId(item, index)
      }))
    }
  }
}
</script>

<style scoped>
.observations {
  padding: 8px 66px;
  word-break: break-word;
}

.ghost {
  opacity: 0.5;
  background: #f5f5f5;
}
</style>
