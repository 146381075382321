import { cloneDeep } from 'lodash'
import AuthService from '@/services/authentication-service.js'
import axiosInstance from '@/services/axios-instance'

const defaultRegisterForm = {
  fullname: '',
  email: '',
  password: '',
  confirmationPassword: '',
  address: {},
  paymentInformation: {
    creditCard: {
    }
  }
}

const CHECK_ACTIVE_TIME_MILISSECONDS = 60 * 1000
export default {
  namespaced: true,
  state: {
    registerForm: cloneDeep(defaultRegisterForm),
    user: {
      isAdmin () { return this.role_type === 'Admin' },
      isPersonal () { return this.role_type === 'PersonalTrainer' },
      isStudent () { return this.role_type === 'Student' },
      isStudentPersonal () { return this.email_secundario !== '' }
    },
    checkSession: null
  },
  mutations: {
    SET_REGISTER_FORM (state, payload) {
      Object.assign(state.registerForm, { ...state.registerForm, ...payload })
    },
    SET_USER (state, payload) {
      state.user = { ...state.user, ...payload }
    },
    ACTIVE_CHECK_SESSION (state) {
      state.checkSession = setInterval(() => {
        AuthService.me()
          .then(({ data }) => {
            state.user = { ...state.user, ...data.user }
            // localStorage.setItem('access_token', axiosInstance.defaults.headers.common.Authorization.split(' ')[1])
          })
          .catch(() => {
            clearInterval(state.checkSession)
          })
      }, CHECK_ACTIVE_TIME_MILISSECONDS)
    },
    STOP_CHECK_SESSION (state) {
      clearInterval(state.checkSession)
    }
  },
  actions: {
    setRegisterForm ({ commit }, payload) {
      commit('SET_REGISTER_FORM', payload)
    },
    resetRegisterForm ({ commit }) {
      commit('SET_REGISTER_FORM', defaultRegisterForm)
    },
    async fetchCurrentUser ({ commit }) {
      return AuthService.me()
        .then(({ data }) => {
          commit('SET_USER', data.user)
          localStorage.setItem('user', JSON.stringify(data.user))
          localStorage.setItem('access_token', axiosInstance.defaults.headers.common.Authorization.split(' ')[1])
        })
    },
    activeCheckSession ({ commit }) {
      commit('ACTIVE_CHECK_SESSION')
    },
    stopCheckSession ({ commit }) {
      commit('STOP_CHECK_SESSION')
    }
  },
  getters: {
    registerForm: state => state.registerForm,
    user: state => state.user
  }
}
