<template>
  <v-container class="py-5 px-4">
    <v-row v-if="!isStudent" class="mt-2">
      <v-col>
        <v-data-table :headers="headers" :items="strengthTests" :items-per-page="50" disable-pagination
          hide-default-footer class="elevation-2">
          <template v-slot:item.action="{ item }">
            <v-btn small icon color="secondary" class="elevation-2" @click="editItem(item, strengthTests)"
              v-if="!disableInput">
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn small icon color="secondary" class="elevation-2" @click="deleteItem(item, strengthTests)"
              v-if="!disableInput">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.percentage="{ item }">
            <v-text-field v-model="item.percentage" type="number"></v-text-field>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="justify-end mt-8">
      <v-btn color="primary" class="mt-2" @click="save" v-btn>
        Salvar Avaliação
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import posturaUploadFilesList from '@/mixins/posturaUploadFilesList'
import axios from '../../../../services/axios-instance'

export default {
  name: 'postura',
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    isStudent: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated'])
  },
  data: () => ({
    items: null,
    files: posturaUploadFilesList,
    photoSelector: 0,
    carousel: null,
    dialogPhotos: false,
    visible: false,
    postura: '',
    headers: [
      {
        text: 'Nome do Exercicio',
        align: 'start',
        value: 'exercise_name'
      },
      { text: 'Repetições Máximas', value: 'repetitions' },
      { text: 'Peso Máximo', value: 'weight' },
      {
        text: 'Grupo Muscular',
        value: 'muscle_group.name'
      },
      {
        text: 'Porcentagem',
        value: 'percentage'
      }
    ],
    strengthTests: [],
    strengthTestsServer: []
  }),
  mounted () {
    // this.items = JSON.parse(JSON.stringify(posturaEvaluationFields))
    // this.loadItems()
    this.getStrengthTests()
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['updateMesocycleToBeEvaluated']),
    getStrengthTests () {
      const studentId = this.student.id
      axios.get(`v1//strength_tests/student/${studentId}`).then(response => {
        this.strengthTests = this.cloneDeepArray(response.data.strength_tests)
        this.strengthTestsServer = this.cloneDeepArray(response.data.strength_tests)
      })
    },
    async save () {
      this.setLoader(true)
      console.log('🚀 ~ file: TesteForca.vue:100 ~ save ~ this.strengthTests', this.strengthTests)
      console.log('🚀 ~ file: TesteForca.vue:100 ~ save ~ this.strengthTestsServer', this.strengthTestsServer)

      const exercisesToSave = this.strengthTests.filter(strengthTest => {
        const isChangedPercentage = this.strengthTestsServer.find(strengthTestServer => {
          return strengthTestServer.id === strengthTest.id && String(strengthTestServer.percentage) !== String(strengthTest.percentage)
        })
        return isChangedPercentage
      })

      try {
        const mapAsync = exercisesToSave.map(async strengthTest => {
          const body = {
            repetitions: strengthTest.repetitions,
            weight: strengthTest.weight,
            percentage: strengthTest.percentage,
            student_id: this.student.id,
            muscle_group_id: strengthTest.muscle_group.id,
            exercise_name: strengthTest.exercise_name
          }
          return this.saveStrengthTest(strengthTest.id, body)
        })

        await Promise.all(mapAsync)

        this.setLoader(false)
        this.setSnackbar({
          color: 'success',
          show: true,
          message: 'Avaliação salva com sucesso!'
        })
        this.$emit('closeDialog')
      } catch (error) {
        this.setLoader(false)
        this.setSnackbar({
          color: 'error',
          show: true,
          message: 'Erro ao salvar avaliação!'
        })
      }
    },
    test () {
      console.log(this.strengthTests)
    },
    cloneDeepArray (array) {
      return JSON.parse(JSON.stringify(array))
    },
    async saveStrengthTest (id, strengthTest) {
      return axios.put(`v1/strength_tests/${id}`, strengthTest)
    }
  }
}
</script>

<style>
.actived {
  border: 1px solid black;
}

.img-new {
  background-image: url("../../../../assets/images/postura/simetrograph.png");
  width: 100%;
  background-position: 52.7% 54.3%;
}
</style>
