<template>
  <v-container class="mt-8">
    <v-row>
      <v-col cols="3">
        <v-row>
          <search-field label="Busque por nomes ou emails" :loading="loadingSearch" @searchText="searchText = $event" />
        </v-row>
      </v-col>
      <v-col>
        <div class="d-flex px-3 justify-between align-end">
          <v-row class="justify-end">
            <filter-select label="Filtros" :filters="filters" :selectedFilter.sync="selectedFilter"
              style="max-width: 200px" />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="ml-2 mr-1 elevation-2" height="38px" outlined @click="currentView = 'card'"
                  v-bind="attrs" v-on="on">
                    <v-icon>mdi-view-grid</v-icon>
                  </v-btn>
                </template>
                <span>Alternar visualização para cartões</span>
              </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mr-1 elevation-2" height="38px" outlined @click="currentView = 'list'"
                v-bind="attrs" v-on="on">
                  <v-icon>mdi-view-list</v-icon>
                </v-btn>
              </template>
              <span>Alternar visualização para lista</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" outlined class="elevation-2" height="38px" @click="fetchSpreadSheet({ isAdmin: false, search: searchText, isActive: selectedFilter.prop})"
                  v-bind="attrs" v-on="on">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download planilha com lista de alunos</span>
            </v-tooltip>
            <v-btn color="primary" class="ml-2" height="38px" @click="newStudent()">
              <v-icon left> mdi-account-plus</v-icon>
              Novo aluno
            </v-btn>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-end py-2">
      <span class="primary--text font-weight-bold px-3">Total de Alunos: {{ totalPages }}</span>
    </v-row>
    <v-row class="ml-n6" v-if="students && students.length > 0">
      <template v-if="currentView === 'card'">
        <student-card v-for="(student, index) in students" :key="`${student.id}-${index}`" :student="student" @editStudent="editStudent" @deleteStudent="deleteStudent(student.id)" />
      </template>
      <template v-else>
        <v-col cols="12">
          <v-row class="border-bottom py-0 px-7 primary--text font-weight-bold mt-2">
            <v-col cols="1">Id</v-col>
            <v-col cols="2">Nome</v-col>
            <v-col cols="1">Idade</v-col>
            <v-col cols="3">E-mail</v-col>
            <v-col cols="2">Celular</v-col>
            <v-col cols="2">Plano</v-col>
            <v-col cols="1">Status</v-col>
          </v-row>
          <v-list-item-group>
            <student-list-item v-for="(student, index) in students" :key="student.id" :student="student"
              :class="index % 2 === 1 ? 'bg-list' : ''" @editStudent="editStudent"
              @deleteStudent="deleteStudent(student.id)"
              @update-status="updateStatus" />
          </v-list-item-group>
        </v-col>
      </template>
    </v-row>

    <v-row class="justify-center align-center mt-10" v-else>
      <span class="headline">
        Alunos não encontrados!
      </span>
    </v-row>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <dialog-full-screen :dialog.sync="dialog" :titleName="titleName" buttonName="Salvar"
      @button="$refs.studentForm.save()">
      <student-form :dialog.sync="dialog" ref="studentForm" @updateList="updateStudentList()" />
    </dialog-full-screen>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StudentCard from '@/components/student/StudentCard'
import studentService from '@/services/student-service'
import StudentNodeService from '@/services/student-node-service'
import SearchField from '@/components/base/SearchField'
import FilterSelect from '@/components/base/FilterSelect'
import DialogFullScreen from '@/components/base/DialogFullScreen'
import StudentForm from '@/components/student/StudentForm'
import InfiniteLoading from 'vue-infinite-loading'
import StudentListItem from '../../components/student/StudentListItem.vue'

export default {
  components: {
    StudentForm,
    DialogFullScreen,
    FilterSelect,
    SearchField,
    StudentCard,
    InfiniteLoading,
    StudentListItem
  },
  name: 'student-list',
  created () {
    this.resetInfinityLoadAndPagination()
  },
  data: () => ({
    loadingSearch: false,
    searchText: '',
    titleName: 'Novo Aluno',
    nextPage: 1,
    totalPages: 0,
    resetList: false,
    state: null,
    currentView: 'card',
    filters: [
      {
        name: 'Todos',
        prop: undefined
      },
      {
        name: 'Ativos',
        prop: 'true'
      },
      {
        name: 'Inativos',
        prop: 'false'
      }
    ],
    selectedFilter: {
      name: 'Ativos',
      prop: 'true'
    },
    dialog: false
  }),
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar', 'fetchSpreadSheet']),
    ...mapActions('students', ['setStudents', 'resetStudent', 'addStudents']),
    infiniteHandler ($state) {
      this.state = $state
      this.resetList = false
      if (this.nextPage === null) {
        $state.complete()
      } else {
        this.getStudents()
      }
    },
    getStudents () {
      this.setLoader({ show: true })
      this.loadingSearch = true

      StudentNodeService.getAllStudents(
        {
          q: this.searchText,
          page: this.nextPage,
          active: this.selectedFilter.prop,
          limit: 24
        })
        .then(({ data }) => {
          console.log('data', data)
          if (data.users.length > 0) {
            if (this.resetList) {
              this.setStudents([])
            }
            this.addStudents(data.users)
            this.nextPage = data.meta.next_page
            this.totalPages = data.meta.total_count
            this.state.loaded()
          } else {
            this.setStudents([])
            this.state.complete()
          }
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao buscar alunos!. Tente novamente mais tarde.'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.loadingSearch = false
        })
    },
    updateStudentList () {
      this.resetList = true
      this.getStudents()
    },
    resetInfinityLoadAndPagination () {
      if (this.state) {
        this.state.reset()
      }
      this.setStudents([])
      this.nextPage = 1
      this.resetList = true
    },
    newStudent () {
      this.resetStudent()
      this.titleName = 'Novo Aluno'
      this.dialog = true
    },
    editStudent () {
      this.titleName = 'Editar Aluno'
      this.dialog = true
    },
    deleteStudent (id) {
      if (confirm('Deseja Realmente Excluir o Cadastro ' + id + '?')) {
        studentService.destroy(id)
          .then(({ data }) => {
            this.setSnackbar({
              show: true,
              color: 'success',
              message: 'Cadastro ' + id + ' Excluido com Sucesso!'
            })
            this.resetList = true
            this.getStudents()
          })
          .catch(({ response }) => {
            this.setSnackbar({
              show: true,
              color: 'success',
              message: 'Erro ao excluir o cadastro ' + id + '! Tente novamente mais tarde.'
            })
          })
          .finally(() => {
            this.setLoader({ show: false })
          })
      }
    },
    updateStatus (status, id) {
      this.setLoader({ show: true })
      studentService.createOrUpdate({ is_active: status }, id)
        .then(({ data }) => {
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Status do cadastro ' + id + ' atualizado com sucesso!'
          })
          this.resetInfinityLoadAndPagination()
        })
        .catch(({ response }) => {
          this.setSnackbar({
            show: true,
            color: 'error',
            message: 'Erro ao atualizar status do cadastro ' + id + '! Tente novamente mais tarde.'
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    }

  },
  computed: {
    ...mapGetters('students', ['students'])
  },
  watch: {
    selectedFilter (val) {
      this.resetInfinityLoadAndPagination()
    },
    searchText (val) {
      this.resetInfinityLoadAndPagination()
    }
  }
}
</script>

<style scoped>
.bg-list {
  background-color: #f5dde2;
}
</style>
