import axios from 'axios'
import VueCookies from 'vue-cookies'

const baseURL = process.env.VUE_APP_API_URL
// const baseURL = 'https://fisic.mestresdaweb.io/'

const axiosInstance = axios.create({
  baseURL: `${baseURL}/api/`
})

axiosInstance.defaults.headers.common.Accept = 'application/json'
axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('access_token') || VueCookies.get('access_token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default axiosInstance
