<template>
  <v-dialog v-model="createOrUpdateExerciseDialog" persistent width="750">
    <v-card>
      <v-form v-model="valid">
        <v-card-title class="secondary white--text" primary-title>
          {{ isNewItem ? 'Novo' : 'Editar' }} Exercício
          <v-spacer></v-spacer>
          <v-btn fab x-small text @click.stop="closeDialog()">
            <v-icon class="white--text">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>

            <!-- Técnica de Intensificação -->
            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="technicSelected" :search-input.sync="searchExerciseTechnics"
                  :items="technicsWithDefault" :loading="loadingSearchExerciseTechnics"
                  no-data-text="Nenhum resultado encontrado" solo dense filled item-text="name" item-value="id"
                  return-object label="Técnica de Intensificação"
                  :rules="[(v) => (v && Object.keys(v).length > 0) || 'Campo obrigatório']"
                  @change="onTechnicChange"></v-autocomplete>
              </v-col>
            </v-row>

            <!-- Exercícios -->
            <v-row v-if="technicSelected">
              <v-col cols="12" v-for="(number, index) in numberExercises" :key="'number-' + index">
                <v-autocomplete v-model="exerciseSelected[index]" :search-input.sync="searchText[index]"
                  :items="exercisesList" :loading="loadingSearch" :disabled="exerciseDeleted"
                  no-data-text="Nenhum resultado encontrado" :menu-props="{ maxHeight: '280px' }" solo dense
                  item-text="name" item-value="id" return-object :label="`Nome do Exercício ${index + 1}`"
                  :rules="[(v) => (v && Object.keys(v).length > 0) || 'Campo obrigatório']"
                  @change="logExerciseSelected">
                  <template v-slot:item="{ item }">
                    <v-layout style="width: 440px">
                      <v-row>
                        <v-col>{{ item.name }}</v-col>
                        <v-col cols="auto" v-if="item.suggested" class="primary--text">
                          (sugerido)
                        </v-col>
                      </v-row>
                    </v-layout>
                  </template>
                </v-autocomplete>

                <v-row v-if="exerciseSelected[index] && exerciseSelected[index].link">
                  <v-col cols="12">
                    <iframe width="100%" height="350" :src="getYoutubeEmbedUrl(index)" title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </v-col>
                </v-row>

                <!-- Campos Específicos por Tipo de Exercício -->
                <v-row v-if="isAerobic(index)" class="my-n6">
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].tempo" class="dense-padding-input-slot" label="Tempo"
                      v-mask="['####']" :rules="[validationRules.required]" filled dense />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].freqCardiaca" class="dense-padding-input-slot"
                      label="Freq Cardíaca" v-mask="['###']" suffix="bpm" :rules="[validationRules.required]" filled
                      dense />
                  </v-col>
                </v-row>

                <v-row v-if="isResistence(index)">
                  <v-col :cols="exerciseDetails[index].peso > 0 ? 3 : 4">
                    <v-text-field v-model="exerciseDetails[index].min_reps" class="dense-padding-input-slot"
                      label="Repetições Mín" v-mask="['####']" filled hide-details="auto" dense />
                  </v-col>
                  <v-col :cols="exerciseDetails[index].peso > 0 ? 3 : 4">
                    <v-text-field v-model="exerciseDetails[index].max_reps" class="dense-padding-input-slot"
                      label="Repetições Máx" :rules="[
                        validationRules.required,
                        (v) => parseInt(v) > parseInt(exerciseDetails[index].min_reps || '0') || 'Deve ser maior que o mínimo',
                      ]" v-mask="['####']" filled hide-details="auto" dense />
                  </v-col>
                  <v-col :cols="exerciseDetails[index].peso > 0 ? 3 : 4">
                    <v-text-field v-model="exerciseDetails[index].cadencia" class="dense-padding-input-slot"
                      label="Cadência" v-mask="['#-#-#-#']" :rules="[cadenceRule]" filled hide-details="auto" dense />
                  </v-col>
                  <v-col cols="3" v-if="exerciseDetails[index].peso > 0">
                    <v-text-field v-model="exerciseDetails[index].peso" class="dense-padding-input-slot" label="Peso"
                      filled hide-details="auto" dense disabled />
                  </v-col>
                </v-row>

                <v-row v-if="isFunctional(index)">
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].min_reps" class="dense-padding-input-slot"
                      label="Repetições Mín" v-mask="['####']" filled hide-details="auto" dense />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].max_reps" class="dense-padding-input-slot"
                      label="Repetições Máx" :rules="[
                        validationRules.required,
                        (v) => parseInt(v) > parseInt(exerciseDetails[index].min_reps || '0') || 'Deve ser maior que o mínimo',
                      ]" v-mask="['####']" filled hide-details="auto" dense />
                  </v-col>
                </v-row>

                <v-row v-if="isMobility(index)">
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].min_reps" class="dense-padding-input-slot"
                      label="Repetições" v-mask="['####']" :rules="[validationRules.required]" filled
                      hide-details="auto" dense />
                  </v-col>
                </v-row>

                <v-row v-if="isStretching(index)">
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].quantidade_tiros" v-mask="timeMask('hh:mm:ss')"
                      class="dense-padding-input-slot" label="Tempo" suffix="hh:mm:ss"
                      :rules="[validationRules.time, validationRules.timeRequired]" filled hide-details="auto" dense />
                  </v-col>
                </v-row>

                <v-row v-if="isMyofascialRelease(index)">
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].tempo" v-mask="timeMask('mm:ss')"
                      class="dense-padding-input-slot" label="Tempo Liberação" suffix="mm:ss"
                      :rules="[validationRules.time, validationRules.timeRequired]" filled dense />
                  </v-col>
                </v-row>

                <v-row v-if="isHiit(index)">
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].quantidade_tiros" class="dense-padding-input-slot"
                      label="Número de Sprints" v-mask="['########']" :rules="[validationRules.required]" filled
                      hide-details="auto" dense />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].freqCardiaca" class="dense-padding-input-slot"
                      label="Freq Cardíaca Alvo" v-mask="['###']" suffix="bpm" :rules="[validationRules.required]"
                      filled dense />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].intervalo_tiros" v-mask="timeMask('mm:ss')"
                      class="dense-padding-input-slot" label="Intervalo entre Sprints" suffix="mm:ss"
                      :rules="[validationRules.time, validationRules.timeRequired]" filled dense />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].tempo_tiros" v-mask="timeMask('mm:ss')"
                      class="dense-padding-input-slot" label="Duração das Sprints" suffix="mm:ss"
                      :rules="[validationRules.time, validationRules.timeRequired]" filled hide-details="auto" dense />
                  </v-col>
                </v-row>

                <v-row v-if="isExAerobico(index)">
                  <v-col cols="6">
                    <v-checkbox v-model="exerciseDetails[index].hiit" label="Hiit" filled required
                      @change="selectHiit(index)" />
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox v-model="exerciseDetails[index].aerobicocont" label="Aeróbico Contínuo" filled
                      @change="selectAerobicoContinuo(index)" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].tempo" class="dense-padding-input-slot" label="Tempo"
                      v-mask="timeMask('hh:mm:ss')" :rules="[validationRules.time, validationRules.timeRequired]"
                      suffix="hh:mm:ss" filled dense @input="formatTime(index)" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].freqCardiaca" class="dense-padding-input-slot"
                      label="Freq Cardíaca" v-mask="['###']" suffix="bpm" :rules="[validationRules.required]" filled
                      dense />
                  </v-col>
                  <v-col cols="6" v-if="exerciseDetails[index].hiit">
                    <v-text-field v-model="exerciseDetails[index].quantidade_tiros" class="dense-padding-input-slot"
                      label="Número de Sprints" v-mask="['########']" :rules="[validationRules.required]" filled
                      hide-details="auto" dense />
                  </v-col>
                  <v-col cols="6" v-if="exerciseDetails[index].hiit">
                    <v-text-field v-model="exerciseDetails[index].intervalo_tiros" v-mask="timeMask('mm:ss')"
                      class="dense-padding-input-slot" label="Intervalo entre Sprints" suffix="mm:ss"
                      :rules="[validationRules.time, validationRules.timeRequired]" filled dense />
                  </v-col>
                </v-row>

                <v-row v-if="exerciseDetails[index] && exerciseDetails[index].hiit">
                  <v-col cols="12">Dias Da Semana Hiit</v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="exerciseDetails[index].seghiit" label="seg" filled />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="exerciseDetails[index].terhiit" label="ter" filled />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="exerciseDetails[index].quahiit" label="qua" filled />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="exerciseDetails[index].quihiit" label="qui" filled />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="exerciseDetails[index].sexhiit" label="sex" filled />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="exerciseDetails[index].sabhiit" label="sab" filled />
                  </v-col>
                  <v-col cols="1">
                    <v-checkbox v-model="exerciseDetails[index].domhiit" label="dom" filled />
                  </v-col>
                </v-row>

                <v-row v-if="isresp(index)">
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].quantidade_tiros" class="dense-padding-input-slot"
                      label="Repetições em isometria" v-mask="['########']" :rules="[validationRules.required]" filled
                      hide-details="auto" dense />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].tempo" v-mask="timeMask('mm:ss')"
                      class="dense-padding-input-slot" label="Tempo de Cada Repetição" suffix="mm:ss"
                      :rules="[validationRules.time, validationRules.timeRequired]" filled dense />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="exerciseDetails[index].intervalo_tiros" v-mask="timeMask('mm:ss')"
                      class="dense-padding-input-slot" label="Intervalo entre cada Repetição" suffix="mm:ss"
                      :rules="[validationRules.time, validationRules.timeRequired]" filled dense />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="technicSelected.name === 'SUPER SERIE'">
                <v-btn color="primary" text @click="addExerciseToSuperSerie" :disabled="loadingSearch">
                  <v-icon left>mdi-plus</v-icon>
                  Adicionar Outro Exercício
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="technicSelected">
              <v-col>
                <v-text-field v-model="exerciseInfo.series" class="dense-padding-input-slot" label="Séries"
                  v-mask="['#####']" :rules="[validationRules.required]" filled hide-details="auto" dense />
              </v-col>
              <v-col>
                <v-text-field v-model="exerciseInfo.descanco" v-mask="timeMask('mm:ss')"
                  class="dense-padding-input-slot" label="Descanso" suffix="mm:ss"
                  :rules="[validationRules.time, validationRules.timeRequired]" filled hide-details="auto" dense />
              </v-col>
            </v-row>
            <v-row v-if="technicSelected">
              <v-col>
                <v-textarea v-model="exerciseInfo.observacao" class="dense-padding-input-slot"
                  label="Observações (opcional)" filled hide-details="auto" dense />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" text @click.stop="closeDialog()">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()" :disabled="!valid">
            {{ isNewItem ? 'Adicionar' : 'Atualizar' }} Exercício
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

  </v-dialog>
</template>

<script>
/*eslint-disable*/
import timeMask from '@/mixins/timeMask'
import validationRules from '@/mixins/validation-rules'
import ExerciseTechnicsService from '@/services/user-exercise-technics-service'
import ExercisesService from '@/services/user-exercises-service'
import MesocycleService from '@/services/mesocycle-service.js'
import { mapActions, mapGetters } from 'vuex'
import axios from '@/services/axios-instance'
export default {
  name: 'create-or-update-exercise-dialog',
  components: {
  },
  mixins: [timeMask],
  data() {
    return {
      searchExerciseTechnics: '',
      exerciseTechnics: [],
      loadingSearchExerciseTechnics: false,
      technicSelected: null,
      numberExercises: 0,
      exerciseSelected: [],
      searchText: [],
      valid: false,
      exibeHiit: true,
      exibeaerobico: true,
      validationRules,
      exercises: [],
      loadingSearch: false,
      exerciseInfo: {
        tempo: '00:00',
        freqCardiaca: '',
        series: '',
        min_reps: '',
        max_reps: '',
        descanco: '',
        cadencia: '',
        observacao: '',
        aerobicocont: false,
        hiit: false,
        seghiit: '',
        teriit: '',
        quahiit: '',
        quihiit: '',
        sexhiit: '',
        sabhiit: '',
        domhiit: '',
        segaer: '',
        teraer: '',
        quaaer: '',
        quiaer: '',
        sexaer: '',
        sabaer: '',
        domaer: '',
        exerciseTechnic: {}
      },
      strengthTests: [],
      cycleToBeEvaluated: {
        trainings: []
      }
    }
  },
  methods: {
    ...mapActions('students', ['setCreateOrUpdateExerciseDialog', 'setCycleType']),
    formatTime(index) {
      let time = this.exerciseDetails[index].tempo || '';

      // Remove caracteres não numéricos
      time = time.replace(/\D/g, '');

      // Se o tempo tiver menos de 6 dígitos (hhmmss), ajusta
      if (time.length <= 4) {
        // Caso seja apenas mm:ss (ex.: 0300 para 03:00)
        time = time.padStart(6, '0'); // Completa com zeros à esquerda até 6 dígitos (ex.: 000300)
      }

      // Formata como hh:mm:ss
      if (time.length === 6) {
        this.exerciseDetails[index].tempo = `${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4, 6)}`;
      }
    },
    save() {
      this.setCycle()
      this.loadingSearch = true

      // Formatar o tempo antes de salvar
      this.exerciseDetails.forEach((detail, index) => {
        if (this.isAerobic(index) || this.isExAerobico(index)) {
          this.formatTime(index); // Garante que o tempo esteja no formato hh:mm:ss
        }
      });

      const combinedExercises = this.exerciseSelected.map((exercise, index) => {
        return {
          ...exercise, // Copia todos os dados do exercício
          min_reps: this.exerciseDetails[index].min_reps,
          max_reps: this.exerciseDetails[index].max_reps
        }
      })

      const payload = {
        // sq: this.isNewItem ? this.cycleToBeEvaluated.trainings[this.trainingIndex].exercises.length : this.createOrUpdateExerciseIndex,
        // tipo_conjugado: this.technicSelected.name === 'Sem Técnica Avançada' ? null : this.technicSelected.name,
        exercise: combinedExercises, // Array com todos os exercícios selecionados
        tempo: this.exerciseDetails[0].tempo,
        freqCardiaca: this.exerciseDetails[0].freqCardiaca,
        series: this.exerciseInfo.series,
        intervalo_tiros: this.exerciseDetails[0].intervalo_tiros,
        tempo_tiros: this.exerciseDetails[0].tempo_tiros,
        quantidade_tiros: this.exerciseDetails[0].quantidade_tiros,
        descanco: this.exerciseInfo.descanco,
        cadencia: this.exerciseDetails[0].cadencia,
        observacao: this.exerciseInfo.observacao,
        aerobicocont: this.exerciseDetails[0].aerobicocont,
        hiit: this.exerciseDetails[0].hiit,
        seghiit: this.exerciseDetails[0].seghiit,
        teriit: this.exerciseDetails[0].teriit,
        quahiit: this.exerciseDetails[0].quahiit,
        quihiit: this.exerciseDetails[0].quihiit,
        sexhiit: this.exerciseDetails[0].sexhiit,
        sabhiit: this.exerciseDetails[0].sabhiit,
        domhiit: this.exerciseDetails[0].domhiit,
        segaer: this.exerciseDetails[0].segaer,
        teraer: this.exerciseDetails[0].teraer,
        quaaer: this.exerciseDetails[0].quaaer,
        quiaer: this.exerciseDetails[0].quiaer,
        sexaer: this.exerciseDetails[0].sexaer,
        sabaer: this.exerciseDetails[0].sabaer,
        domaer: this.exerciseDetails[0].domaer,
        exercise_technic: this.technicSelected.name === 'Sem Técnica Avançada' ? null : this.technicSelected
      }

      if (this.isNewItem) {
        this.cycleToBeEvaluated.trainings[this.trainingIndex].exercises.push(payload)
      } else {
        this.cycleToBeEvaluated.trainings[this.trainingIndex].exercises.splice(
          this.createOrUpdateExerciseIndex,
          1,
          payload
        )
      }

      this.$emit('update', {
        successMessage: 'Exercício salvo com sucesso',
        failMessage: 'Erro ao salvar exercício',
        cycle: this.cycleType
      })

      this.loadingSearch = false
      this.closeDialog()
    },

    onTechnicChange() {
      if (this.technicSelected) {
        let previousExercises = [...this.exerciseSelected];
        switch (this.technicSelected.name) {
          case 'BI-SET':
            this.numberExercises = 2;
            this.exerciseSelected = previousExercises.length ? previousExercises.slice(0, 2) : Array(2).fill(null);
            this.searchText = Array(2).fill('');
            this.exerciseDetails = Array(2).fill().map(() => ({
              tempo: '00:00', freqCardiaca: '', min_reps: '', max_reps: '', quantidade_tiros: '', intervalo_tiros: '', tempo_tiros: '', cadencia: '', peso: 0,
              aerobicocont: false, hiit: false, seghiit: '', terhiit: '', quahiit: '', quihiit: '', sexhiit: '', sabhiit: '', domhiit: '',
              exerciseTechnic: {},
            }));
            break;
          case 'TRI-SET':
            this.numberExercises = 3;
            this.exerciseSelected = previousExercises.length ? previousExercises.slice(0, 3) : Array(3).fill(null);
            this.searchText = Array(3).fill('');
            this.exerciseDetails = Array(3).fill().map(() => ({
              tempo: '00:00', freqCardiaca: '', min_reps: '', max_reps: '', quantidade_tiros: '', intervalo_tiros: '', tempo_tiros: '', cadencia: '', peso: 0,
              aerobicocont: false, hiit: false, seghiit: '', terhiit: '', quahiit: '', quihiit: '', sexhiit: '', sabhiit: '', domhiit: '',
              exerciseTechnic: {},
            }));
            break;
          case 'SUPER SERIE':
            this.numberExercises = Math.max(2, previousExercises.length);
            this.exerciseSelected = previousExercises.length ? previousExercises : Array(2).fill(null);
            this.searchText = Array(this.numberExercises).fill('');
            this.exerciseDetails = Array(this.numberExercises).fill().map(() => ({
              tempo: '00:00', freqCardiaca: '', min_reps: '', max_reps: '', quantidade_tiros: '', intervalo_tiros: '', tempo_tiros: '', cadencia: '', peso: 0,
              aerobicocont: false, hiit: false, seghiit: '', terhiit: '', quahiit: '', quihiit: '', sexhiit: '', sabhiit: '', domhiit: '',
              exerciseTechnic: {},
            }));
            break;
          default:
            this.numberExercises = 1;
            this.exerciseSelected = previousExercises.length ? [previousExercises[0]] : [null];
            this.searchText = Array(1).fill('');
            this.exerciseDetails = Array(1).fill().map(() => ({
              tempo: '00:00', freqCardiaca: '', min_reps: '', max_reps: '', quantidade_tiros: '', intervalo_tiros: '', tempo_tiros: '', cadencia: '', peso: 0,
              aerobicocont: false, hiit: false, seghiit: '', terhiit: '', quahiit: '', quihiit: '', sexhiit: '', sabhiit: '', domhiit: '',
              exerciseTechnic: {},
            }));
            break;
        }
        this.fetchSuggestedExercises('');
      }
    },
    addExerciseToSuperSerie() {
      this.numberExercises++;
      this.exerciseSelected.push(null);
      this.searchText.push('');
      this.exerciseDetails.push({
        tempo: '00:00',
        freqCardiaca: '',
        min_reps: '',
        max_reps: '',
        quantidade_tiros: '',
        intervalo_tiros: '',
        tempo_tiros: '',
        cadencia: '',
        peso: 0,
        aerobicocont: false,
        hiit: false,
        seghiit: '',
        terhiit: '',
        quahiit: '',
        quihiit: '',
        sexhiit: '',
        sabhiit: '',
        domhiit: '',
        exerciseTechnic: {},
      });
    },
    closeDialog() {
      this.setCreateOrUpdateExerciseDialog(false)
      this.technicSelected = null
      this.exerciseSelected = null
      this.exerciseInfo = { exerciseTechnic: {} }
      this.exercises = []
      this.exerciseTechnics = []
    },
    selectAerobicoContinuo(index) {
      if (this.exerciseDetails[index].aerobicocont) {
        this.exerciseDetails[index].hiit = false;
      }
    },
    selectHiit(index) {
      if (this.exerciseDetails[index].hiit) {
        this.exerciseDetails[index].aerobicocont = false;
      }
    },
    fetchSuggestedExercises(query) {
      this.loadingSearch = true
      let payload = {
        q: query,
        trainingIndex: this.trainingIndex,
        mesocycleId: this.mesocycleToBeEvaluated.id
      };

      if (this.cycleType === 'micro') {
        payload.microcycleId = this.microcycleToBeEvaluated.id
      }

      ExercisesService.suggested(payload)
        .then(({ data }) => {
          const { exercises } = data
          exercises.sort((exercise) => exercise.suggested ? -1 : 1)
          this.exercises = exercises
        })
        .catch((error) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: error
          })
        })
        .finally(() => {
          this.loadingSearch = false
        })
    },
    fetchExerciseTechnics(query) {
      this.loadingSearchExerciseTechnics = true
      ExerciseTechnicsService.all({ q: query })
        .then(({ data }) => {
          this.exerciseTechnics = data.exercise_technics
        })
        .catch((error) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: error
          })
        })
        .finally(() => {
          this.loadingSearchExerciseTechnics = false
        })
    },
    cadenceRule(cadence) {
      return (!cadence || cadence.length == 7) || "Campo com formato inválido"
    },
    getStrengthTests() {
      const studentId = this.student.id;
      axios.get(`v1/strength_tests/student/${studentId}`).then((response) => {
        const strengthTests = response.data.strength_tests;
        this.exerciseDetails.forEach((detail, index) => {
          const strengthTestForMuscle = strengthTests.find(
            (strengthTest) =>
              strengthTest &&
              strengthTest.muscle_group &&
              strengthTest.muscle_group.id === this.exerciseSelected[index]?.muscle_group_id
          );
          if (!strengthTestForMuscle) {
            this.exerciseDetails[index].peso = 0;
          } else {
            const weightWithPercentage =
              strengthTestForMuscle.weight * (strengthTestForMuscle.percentage / 100);
            this.exerciseDetails[index].peso = Math.round(weightWithPercentage);
          }
        });
      });
    },
    logExerciseSelected() {
      this.getStrengthTests();
    },
    setCycle() {
      this.cycleToBeEvaluated = this.cycleType === 'micro' ? this.microcycleToBeEvaluated : this.mesocycleToBeEvaluated
    },
    getYoutubeEmbedUrl(index) {
      if (this.exerciseSelected[index] && this.exerciseSelected[index].link) {
        const link = this.exerciseSelected[index].link;
        const youtubeId = link.split('/').pop().split('?')[0];
        return `https://www.youtube.com/embed/${youtubeId}`;
      }
      return '';
    },
    isAerobic(index) {
      return this.exerciseSelected[index] && ['aerobic'].includes(this.exerciseSelected[index].exercise_type);
    },
    isResistence(index) {
      return this.exerciseSelected[index] && ['resistence'].includes(this.exerciseSelected[index].exercise_type);
    },
    isFunctional(index) {
      return this.exerciseSelected[index] && ['functional'].includes(this.exerciseSelected[index].exercise_type);
    },
    isMobility(index) {
      return this.exerciseSelected[index] && ['mobility'].includes(this.exerciseSelected[index].exercise_type);
    },
    isStretching(index) {
      return this.exerciseSelected[index] && ['stretching'].includes(this.exerciseSelected[index].exercise_type);
    },
    isMyofascialRelease(index) {
      return this.exerciseSelected[index] && ['myofascial_release'].includes(this.exerciseSelected[index].exercise_type);
    },
    isHiit(index) {
      return this.exerciseSelected[index] && ['hiit'].includes(this.exerciseSelected[index].exercise_type);
    },
    needsCadence(index) {
      return this.isResistence(index);
    },
    isExAerobico(index) {
      return this.exerciseSelected[index] && ['exerc_aerobicos'].includes(this.exerciseSelected[index].exercise_type);
    },
    isresp(index) {
      return this.exerciseSelected[index] && ['manobra_resp'].includes(this.exerciseSelected[index].exercise_type);
    },
  },
  computed: {
    ...mapGetters('students', ['cycleType', 'createOrUpdateExerciseDialog', 'mesocycleToBeEvaluated', 'microcycleToBeEvaluated', 'trainingIndex', 'createOrUpdateExercise', 'createOrUpdateExerciseIndex', 'student']),
    isNewItem() {
      return this.createOrUpdateExerciseIndex === -1
    },
    technicsWithDefault() {
      return [{ id: 'none', name: 'Sem Técnica Avançada' }, ...this.exerciseTechnics];
    },
    exercisesList() {
      this.setCycle()
      if (!this.cycleToBeEvaluated.trainings || !this.cycleToBeEvaluated.trainings[this.trainingIndex]) return this.exerciseSelected.filter(ex => ex) || []
      const trainingExercises = this.cycleToBeEvaluated.trainings[this.trainingIndex].exercises
        .flatMap(item => Array.isArray(item.exercise) ? item.exercise : [item.exercise])
      const filteredExercises = this.isNewItem
        ? this.exercises.filter(exercise => !trainingExercises.some(item => item?.id === exercise.id))
        : this.exercises
      return [
        ...this.exerciseSelected.filter(ex => ex),
        ...filteredExercises.filter(ex => !this.exerciseSelected.some(sel => sel?.id === ex.id))
      ]
    },
    exerciseDeleted() {
      return !this.isNewItem && this.exercises.length > 0 &&
        !this.exerciseSelected.some(selected => selected && this.exercises.some(ex => ex.id === selected.id)) &&
        this.technicSelected !== null;
    }

  },
  watch: {
    createOrUpdateExerciseDialog(newVal) {
      if (newVal) {
        this.fetchExerciseTechnics('')
        this.fetchSuggestedExercises('')
      }
    },
    createOrUpdateExercise: {
    handler(newVal) {
      if (newVal) {
        if (Array.isArray(newVal.exercise)) {
          this.numberExercises = newVal.exercise.length;
          this.exerciseSelected = [...newVal.exercise];
          this.searchText = newVal.exercise.map(ex => ex.name || '');
          this.technicSelected = newVal.exercise_technic || { id: 'none', name: 'Sem Técnica Avançada' };
          this.exerciseDetails = newVal.exercise.map((ex, index) => {
            let tempo = newVal.tempo || '00:00';
            if (tempo && tempo.length === 5) tempo = `00:${tempo}`;
            return {
              tempo: tempo,
              freqCardiaca: newVal.freqCardiaca || '',
              min_reps: ex.min_reps || '',
              max_reps: ex.max_reps || '',
              quantidade_tiros: newVal.quantidade_tiros || '',
              intervalo_tiros: newVal.intervalo_tiros || '',
              tempo_tiros: newVal.tempo_tiros || '',
              cadencia: newVal.cadencia || '',
              peso: 0,
              aerobicocont: newVal.aerobicocont || false,
              hiit: newVal.hiit || false,
              seghiit: newVal.seghiit || '',
              terhiit: newVal.teriit || '',
              quahiit: newVal.quahiit || '',
              quihiit: newVal.quihiit || '',
              sexhiit: newVal.sexhiit || '',
              sabhiit: newVal.sabhiit || '',
              domhiit: newVal.domhiit || '',
              segaer: newVal.segaer || '',
              teraer: newVal.teraer || '',
              quaaer: newVal.quaaer || '',
              quiaer: newVal.quiaer || '',
              sexaer: newVal.sexaer || '',
              sabaer: newVal.sabaer || '',
              domaer: newVal.domaer || '',
              exerciseTechnic: newVal.exercise_technic || {},
            };
          });
        } else {
          this.numberExercises = 1;
          this.exerciseSelected = newVal.exercise ? [newVal.exercise] : [null];
          this.searchText = newVal.exercise ? [newVal.exercise.name || ''] : [''];
          this.technicSelected = newVal.exercise_technic || { id: 'none', name: 'Sem Técnica Avançada' };
          let tempo = newVal.tempo || '00:00';
          if (tempo && tempo.length === 5) tempo = `00:${tempo}`;
          this.exerciseDetails = [{
            tempo: tempo,
            freqCardiaca: newVal.freqCardiaca || '',
            min_reps: newVal.min_reps || '',
            max_reps: newVal.max_reps || '',
            quantidade_tiros: newVal.quantidade_tiros || '',
            intervalo_tiros: newVal.intervalo_tiros || '',
            tempo_tiros: newVal.tempo_tiros || '',
            cadencia: newVal.cadencia || '',
            peso: 0,
            aerobicocont: newVal.aerobicocont || false,
            hiit: newVal.hiit || false,
            seghiit: newVal.seghiit || '',
            terhiit: newVal.teriit || '',
            quahiit: newVal.quahiit || '',
            quihiit: newVal.quihiit || '',
            sexhiit: newVal.sexhiit || '',
            sabhiit: newVal.sabhiit || '',
            domhiit: newVal.domhiit || '',
            segaer: newVal.segaer || '',
            teraer: newVal.teraer || '',
            quaaer: newVal.quaaer || '',
            quiaer: newVal.quiaer || '',
            sexaer: newVal.sexaer || '',
            sabaer: newVal.sabaer || '',
            domaer: newVal.domaer || '',
            exerciseTechnic: newVal.exercise_technic || {},
          }];
        }

        this.exerciseInfo = {
          tempo: newVal.tempo || '00:00',
          freqCardiaca: newVal.freqCardiaca || '',
          series: newVal.series || '',
          intervalo_tiros: newVal.intervalo_tiros || '',
          tempo_tiros: newVal.tempo_tiros || '',
          quantidade_tiros: newVal.quantidade_tiros || '',
          min_reps: '',
          max_reps: '',
          descanco: newVal.descanco || '',
          cadencia: newVal.cadencia || '',
          observacao: newVal.observacao || '',
          aerobicocont: newVal.aerobicocont || false,
          hiit: newVal.hiit || false,
          seghiit: newVal.seghiit || '',
          teriit: newVal.teriit || '',
          quahiit: newVal.quahiit || '',
          quihiit: newVal.quihiit || '',
          sexhiit: newVal.sexhiit || '',
          sabhiit: newVal.sabhiit || '',
          domhiit: newVal.domhiit || '',
          segaer: newVal.segaer || '',
          teraer: newVal.teraer || '',
          quaaer: newVal.quaaer || '',
          quiaer: newVal.quiaer || '',
          sexaer: newVal.sexaer || '',
          sabaer: newVal.sabaer || '',
          domaer: newVal.domaer || '',
          exerciseTechnic: newVal.exercise_technic || {}
        };
      }
    }
  },
    technicSelected: {
      handler(newVal) {
        this.fetchSuggestedExercises('')
      }
    },
    exerciseSelected: {
      handler(newVal) {
        // this.getStrengthTests()
      }
    },
    cycleType(newVal) {
      if (newVal) {
        this.setCycle()
      }
    }
  }
}
</script>

<style lang="scss"></style>
