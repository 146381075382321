<template>
  <v-dialog
    :key="`dialog-${dialog}`"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card>
      <v-form
        v-model="valid"
      >
      <v-card-title
        class="secondary white--text"
        primary-title
      >
        {{ modalTitle }} Mesociclo
        <v-spacer></v-spacer>
        <v-btn
          fab
          x-small
          text
          @click.stop="closeDialog()"
        >
          <v-icon
            class="white--text"
          >
            mdi-close
          </v-icon>

        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="editedItem.name"
                label='Nome do Meso'
                :rules="[validationRules.required]"
                filled
                required
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="editedItem.goal"
                label="Objetivo do Mesociclo"
                :rules="[validationRules.required]"
                filled
                required
              />
            </v-col>
          </v-row>

          <v-row v-if="editedItem.weeks > 0 || isNewItem()">
            <v-col cols="12">
              <span class="custom-label">Criar Microciclos</span>
              <label class="switch mb-5">
                <input type="checkbox" v-model="showMicrociclos" />
                <span class="slider"></span>
              </label>
              <v-text-field
                v-if="showMicrociclos"
                v-model="editedItem.weeks"
                label="Quantidade de Semanas/Microciclos"
                :disabled="editedItem.weeks > 0 && !isNewItem()"
                filled
                readonly
              >
                <template #prepend-inner>
                  <v-btn
                    icon
                    color="primary"
                    elevation="0"
                    @click="decreaseWeeks"
                    class="mx-2"
                    :disabled="editedItem.weeks > 0 && !isNewItem()"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <template #append>
                  <v-btn
                    icon
                    color="primary"
                    elevation="0"
                    @click="increaseWeeks"
                    class="mx-2"
                    :disabled="editedItem.weeks > 0 && !isNewItem()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
            >
              <date-picker-field
                v-model="editedItem.start_date"
                :rules="[validationRules.required]"
                @input="setEndDate"
                :locale="'pt-BR'"
                label="Início"
              />

            </v-col>
            <v-col
              cols="6"
            >
              <date-picker-field
                :key="editedItem.end_date"
                v-model="editedItem.end_date"
                :rules="[validationRules.required]"
                label="Fim"
                :min="min(editedItem.start_date)"
                :locale="'pt-BR'"
                :disabled="editedItem.weeks >= 1"
                />

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click.stop="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="saveMicrocycles()"
          :disabled="!valid"
        >
          Salvar
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
    <confirm-dialog :title="'Criar mesociclo com: ' + editedItem.weeks + ' microciclos'"
    message="Após a criação do Mesociclo com microciclos, não poderá alterar a quantidade de microciclos." cancelButton="Cancelar"
    confirmButton="Continuar" :confirmationDialog="saveWithMicrocycles" @confirm="save()"
    @cancel="() => saveWithMicrocycles = false" />
  </v-dialog>
</template>

<script>
import validationRules from '@/mixins/validation-rules'
import DatePickerField from '@/components/base/DatePickerField'
import { mapActions } from 'vuex'
import mesocycleService from '@/services/mesocycle-service'
import ConfirmDialog from '@/components/base/ConfirmDialog'

export default {
  name: 'create-or-update-meso-dialog',
  components: {
    ConfirmDialog,
    DatePickerField
  },
  props: ['editedItem', 'editedIndex', 'dialog', 'macrocycleId'],
  data () {
    return {
      valid: false,
      showMicrociclos: false,
      saveWithMicrocycles: false,
      validationRules,
      trainingSystem: [
        { name: 'Adaptação à Hipertrofia', value: 1 },
        { name: 'Ficar Monstro', value: 2 }
      ]
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['setMesocycles']),
    saveMicrocycles () {
      if ((!this.saveWithMicrocycles && !this.editedItem.weeks) || !this.isNewItem()) {
        this.save()
      } else {
        this.saveWithMicrocycles = true
      }
    },
    save () {
      this.setLoader({ show: true })
      mesocycleService
        .createOrUpdate(this.studentId, this.macrocycleId, this.editedItem)
        .then(async ({ data }) => {
          const responseData = await mesocycleService.all(this.studentId, this.macrocycleId)
          this.setMesocycles({ macrocycleId: this.macrocycleId, mesocycles: responseData.mesocycles })
          this.showSnackbar('success', `Mesociclo ${this.isNewItem() ? 'cadastrada' : 'atualizada '} com sucesso`)
          this.closeDialog()
        })
        .catch(({ response }) => {
          this.showSnackbar('error', response.data.errors.detail)
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    closeDialog () {
      this.$emit('update:dialog', false)
    },
    showSnackbar (color, message) {
      this.setSnackbar({
        color,
        message,
        show: true
      })
    },
    isNewItem () {
      return this.editedIndex === -1
    },
    min (date) {
      if (!date) {
        return new Date().toISOString().substring(0, 10)
      }
      var newDate = Date.parse(date) + (24 * 60 * 60 * 1000)
      newDate = new Date(newDate).toISOString().substring(0, 10)
      return newDate
    },
    setEndDate () {
      if (this.editedItem?.weeks) {
        let days = this.editedItem.weeks * 7
        let newDate = Date.parse(this.editedItem.start_date) + (days * 24 * 60 * 60 * 1000)
        this.editedItem.end_date = new Date(newDate - 1).toISOString().substring(0, 10)
      } else {
        this.editedItem.end_date = this.min(this.editedItem.start_date)
      }
    },
    increaseWeeks () {
      if (this.editedItem.weeks < 12) this.editedItem.weeks++
      this.setEndDate()
    },
    decreaseWeeks () {
      if (this.editedItem.weeks > 1) this.editedItem.weeks--
      else this.editedItem.weeks = null
      this.setEndDate()
    }
  },
  computed: {
    modalTitle () {
      return this.isNewItem() ? 'Cadastrar' : 'Atualizar'
    },
    studentId () {
      return this.$route.params.id
    },
    shouldShowMicrociclos () {
      return this.editedItem.weeks > 0 && !this.isNewItem()
    }
  },
  watch: {
    showMicrociclos (newValue) {
      if (!newValue && !this.editedItem.weeks) {
        this.editedItem.weeks = null
        this.setEndDate()
      }
    },
    shouldShowMicrociclos (newValue) {
      this.showMicrociclos = newValue // Atualiza a variável reativa com base na computed
    }
  }
}
</script>

<style scoped lang="scss">
.readonly-picker {
  pointer-events: none;
  user-select: none;
}
.custom-label {
  font-weight: 600;
  color: #382f2f;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
</style>
