<template>
  <dialog-full-screen :dialog="studentTrainingDialog" titleName="Treino" @close="closeDialog()">
    <v-container>
      <student-header-info />

      <template v-if="isEmptyState">
        <v-row justify="center" align="center" class="py-12">
          <v-col>
            <v-card elevation=0 height="277px">
              <v-img contain height="90px" :src="require('@/assets/images/students/show/no-data-training.svg')"></v-img>
              <v-card-text class="text-center mb-0">
                Ainda não há nenhum treino cadastrado.
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn v-if="!readonly" @click="newTraining()" color="primary">novo treino</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation=0 height="277px">
              <v-card-actions class="justify-center">
                <v-row>
                  <v-col>
                    <v-checkbox v-model="thisStudent" label='Mesmo Aluno' filled required @click="clearStudent()" />
                  </v-col>
                  <v-col>
                    <v-text-field v-if="!thisStudent" v-model="studentcopy" label='Código Aluno' filled required />
                  </v-col>
                  <v-col class="justify-center">
                    <v-btn v-if="!readonly" @click="loadMesocycle()" color="primary">Pesquisar</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions class="justify-center">
                <v-row>
                  <v-col>
                    <v-text-field v-model="studentSel" label='Aluno Selecionado' filled required disabled />
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions class="justify-center">
                <v-select v-model="mesoCopy" label="Selecione o Mesociclo que deseja copiar o treino"
                  :items="mesocycles" return-object item-value="id" item-text="name" item-color="primary"
                  :menu-props="{ nudgeBottom: '64px' }" chips hide-details filled dense>
                  <template slot="item" slot-scope="data">
                    Macro: {{ data.item.macrocycle.name }} - {{ data.item.name }}
                  </template>
                </v-select>
              </v-card-actions>
              <v-card-actions class="justify-center">
                <v-btn v-if="!readonly" @click="copyTraining()" color="primary">Copiar treino</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row no-gutters>
          <v-slide-group>
            <v-col cols="auto" class="pr-2" v-for="(weekday, idx) in week" :key="idx">
              <v-card width="163px" height="100%">
                <v-card-title class="justify-center"
                  :class="isEmptyWeekdayTraining(weekday, mesocycleToBeEvaluated.trainings) ? 'lightgrey text--secondary' : 'secondary white--text'">
                  {{ weekday.name }}
                </v-card-title>
                <template v-if="isEmptyWeekdayTraining(weekday, mesocycleToBeEvaluated.trainings)">
                  <v-img contain height="72px" class="mt-8"
                    :src="require('@/assets/images/students/show/no-data-training.svg')"></v-img>
                  <v-card-text class="text-center mb-0 caption">
                    Não há treino neste dia.
                  </v-card-text>
                </template>
                <template v-else>
                  <v-row justify="center" class="mx-0 grey lighten-4">
                    <v-subheader>
                      <span class="primary--text font-weight-bold justify-center">{{
                        getMuscleGroupSeriesFromWeekday(weekday,
                          mesocycleToBeEvaluated.trainings).name }}</span>
                    </v-subheader>
                  </v-row>
                  <template
                    v-if="getMuscleGroupSeriesFromWeekday(weekday, mesocycleToBeEvaluated.trainings).worked_muscle_groups.length">
                    <v-row class="pa-0 px-3 mt-n4" style="font-size: 10px;">
                      <v-col class="grey lighten-4" cols="8">Grupo muscular</v-col>
                      <v-col class="grey lighten-4 text-right" cols="4">Séries</v-col>
                    </v-row>
                    <v-list-item
                      v-for="(workedMuscleGroup, idx) in getMuscleGroupSeriesFromWeekday(weekday, mesocycleToBeEvaluated.trainings).worked_muscle_groups"
                      :key="`muscle-group${idx}`">
                      <v-list-item-subtitle class="text-left">{{ workedMuscleGroup.name }} </v-list-item-subtitle>
                      <v-list-item-action class="pl-8 ml-n8">{{ workedMuscleGroup.seriesAmount }} </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <template
                      v-if="getMuscleGroupSeriesFromWeekday(weekday, mesocycleToBeEvaluated.trainings).exercisesCount">
                      <v-img contain height="72px" class="mt-8"
                        :src="require('@/assets/images/students/show/no-resistence-training.svg')"></v-img>
                      <v-card-text class="text-center mb-0 caption">
                        Não há exercícios resistidos neste treino.
                      </v-card-text>
                    </template>
                    <template v-else>
                      <v-img contain height="72px" class="mt-8"
                        :src="require('@/assets/images/students/show/no-data-training.svg')"></v-img>
                      <v-card-text class="text-center mb-0 caption">
                        Não há exercícios neste treino.
                      </v-card-text>
                    </template>
                  </template>
                </template>
              </v-card>
            </v-col>
          </v-slide-group>
        </v-row>

        <v-row class="d-flex align-center">
          <v-col cols="8">
            <v-tabs min-width="90px" v-model="tabs" background-color="white" class="training-tabs">
              <v-tab v-for="(training, index) in mesocycleToBeEvaluated.trainings" :key="index">
                {{ training.name }}
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col class="text-right">
            <v-btn color="primary" @click="downloadCurrentTrainingPdf()">
              <span class="ml-1">Baixar PDF</span>
            </v-btn>
            <v-btn class="ml-5 elevation-3" color="secondary" text @click="newTraining()" v-if="!readonly">
              <span class="ml-1">Novo Treino</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(training, idx) in mesocycleToBeEvaluated.trainings" :key="idx">
            <v-container>
              <v-row class="d-flex justify-center">
                <v-col>
                  <span class="title">
                    {{ training.name }}
                  </span>
                  <template v-if="!readonly">
                    <v-btn class="ml-2 elevation-4" fab x-small color="secondary"
                      @click="selectTrainingToBeDestroyed(idx)" text>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn class="ml-2 elevation-4" fab x-small color="secondary" text
                      @click="editTraining(training, idx)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-col>
                <v-spacer />
                <v-col class="text-right" v-if="!readonly">
                  <v-btn color="primary" @click="newExerciseDialog(idx, null)">
                    ADICIONAR EXERCÍCIO
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <student-training-table v-for="exerciseType in getExercisesByType(idx)" :title="exerciseType.title"
                    :headers="exerciseType.headers" :items="exerciseType.items" :readonly="readonly"
                    :trainingIndex="idx" @destroyExerciseDialog="openConfirmationDestroyExerciseDialog"
                    @copyExerciseDialog="openConfirmationCopyExerciseDialog" :key="exerciseType.title" />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-container>

    <confirm-dialog title="Atenção" message="Deseja remover o treino selecionado? Essa ação não poderá ser desfeita"
      cancelButton="Cancelar" confirmButton="Remover" :confirmationDialog="confirmationDialog" @confirm="destroy()"
      @cancel="closeConfirmationDialog()" />

    <confirm-dialog title="Atenção" message="Deseja remover o exercício selecionado? Essa ação não poderá ser desfeita"
      cancelButton="Cancelar" confirmButton="Remover" :confirmationDialog="confirmationDestroyExerciseDialog"
      @confirm="removeExercise()" @cancel="closeConfirmationDestroyExerciseDialog()" />

    <create-or-update-training-dialog :dialog.sync="createOrUpdateTrainingDialog" :editedItem="currentTraining"
      :editedIndex="currentTrainingIndex" @update="update" />

    <copy-training-dialog :dialog.sync="confirmationCopyExerciseDialog" :editedItem="exerciseToBeCopied"
      :editedIndex="currentTrainingIndex" @update="update" />
  </dialog-full-screen>
</template>

<script>
import DialogFullScreen from '@/components/base/DialogFullScreen'
import ConfirmDialog from '@/components/base/ConfirmDialog'
import StudentHeaderInfo from '@/components/student/show/studentEvaluation/StudentHeaderInfo'
import CreateOrUpdateTrainingDialog from '@/components/student/show/CreateOrUpdateTrainingDialog.vue'
import CopyTrainingDialog from '@/components/student/show/CopyTrainingDialog.vue'
import StudentTrainingTable from './StudentTrainingTable.vue'

import DocumentService from '@/services/document-service.js'
import StudentService from '@/services/student-service.js'
import MesocycleService from '@/services/mesocycle-service.js'
import { mapGetters, mapActions } from 'vuex'
import { filter } from 'lodash'

export default {
  name: 'student-training-detail',
  components: {
    DialogFullScreen,
    ConfirmDialog,
    StudentHeaderInfo,
    CreateOrUpdateTrainingDialog,
    StudentTrainingTable,
    CopyTrainingDialog
  },
  data () {
    return {
      week: [
        { name: 'Domingo', id: 0 },
        { name: 'Segunda', id: 1 },
        { name: 'Terça', id: 2 },
        { name: 'Quarta', id: 3 },
        { name: 'Quinta', id: 4 },
        { name: 'Sexta', id: 5 },
        { name: 'Sábado', id: 6 }
      ],
      mesocycles: [],
      name: '',
      mesoCopy: '',
      studentcopy: '',
      studentSel: '',
      thisStudent: true,
      createOrUpdateTrainingDialog: false,
      currentTrainingIndex: -1,
      currentTraining: {},
      onEditTraining: false,
      tabs: null,
      confirmationDialog: false,
      confirmationDestroyExerciseDialog: false,
      trainingToBeDestroyedIndex: -1,
      exerciseToBeDestroyed: null,
      exerciseToBeCopied: null,
      confirmationCopyExerciseDialog: false,
      resistanceHeader: [
        { text: '', sortable: false, value: 'draggable', width: '50' },
        { text: 'Exercício', align: 'left', sortable: false, value: 'exercise.name', width: '300' },
        { text: 'Séries', align: 'center', sortable: false, value: 'series', width: '70' },
        { text: 'Min. Repetições', align: 'center', sortable: false, value: 'min_reps', width: '100' },
        { text: 'Max. Repetições', align: 'center', sortable: false, value: 'max_reps', width: '100' },
        { text: 'Descanso', align: 'center', sortable: false, value: 'descanco', width: '100' },
        { text: 'Cadência', align: 'center', sortable: false, value: 'cadencia', width: '100' },
        { text: 'Técnica de Intensificação', align: 'center', sortable: false, value: 'exercise_technic.name', width: '100' },
        { text: 'Grupos Musculares', align: 'center', sortable: false, value: 'muscleGroups', width: '150' },
        { text: '', align: 'left', sortable: false, value: 'actions', width: '90' }
      ],
      stretchingHeader: [
        { text: '', sortable: false, value: 'draggable', width: '50' },
        { text: 'Exercício', align: 'left', sortable: false, value: 'exercise.name', width: '350' },
        { text: 'Séries', align: 'center', sortable: false, value: 'series', width: '100' },
        { text: 'Tempo', align: 'center', sortable: false, value: 'quantidade_tiros', width: '100' },
        { text: 'Descanso', align: 'center', sortable: false, value: 'descanco', width: '100' },
        { text: '', align: 'left', sortable: false, value: 'actions', width: '191' }
      ],
      mobilityHeader: [
        { text: '', sortable: false, value: 'draggable', width: '50' },
        { text: 'Exercício', align: 'left', sortable: false, value: 'exercise.name', width: '432' },
        { text: 'Séries', align: 'center', sortable: false, value: 'series', width: '100' },
        { text: 'Repetições', align: 'center', sortable: false, value: 'min_reps', width: '100' },
        { text: 'Descanso', align: 'center', sortable: false, value: 'descanco', width: '200' },
        { text: '', align: 'left', sortable: false, value: 'actions', width: '110' }
      ],
      myofascialReleaseHeader: [
        { text: '', sortable: false, value: 'draggable', width: '50' },
        { text: 'Exercício', align: 'left', sortable: false, value: 'exercise.name', width: '350' },
        { text: 'Séries', align: 'center', sortable: false, value: 'series', width: '130' },
        { text: 'Tempo Liberação', align: 'center', sortable: false, value: 'tempo', width: '170' },
        { text: 'Intervalo', align: 'center', sortable: false, value: 'descanco', width: '100' },
        { text: 'Grupos Musculares', align: 'center', sortable: false, value: 'muscleGroups', width: '210' },
        { text: '', align: 'left', sortable: false, value: 'actions', width: '190' }
      ],
      functionalHeader: [
        { text: '', sortable: false, value: 'draggable', width: '50' },
        { text: 'Exercício', align: 'left', sortable: false, value: 'exercise.name', width: '300' },
        { text: 'Min. Repetições', align: 'center', sortable: false, value: 'min_reps', width: '100' },
        { text: 'Max. Repetições', align: 'center', sortable: false, value: 'max_reps', width: '100' },
        { text: 'Grupos Musculares', align: 'center', sortable: false, value: 'muscleGroups', width: '150' },
        { text: '', align: 'left', sortable: false, value: 'actions', width: '90' }
      ],
      AerobicoContHeader: [
        { text: '', sortable: false, value: 'draggable', width: '50' },
        { text: 'Exercício', align: 'left', sortable: false, value: 'exercise.name', width: '300' },
        { text: 'Sprints', align: 'center', sortable: false, value: 'quantidade_tiros', width: '100' },
        { text: 'Duração da Sprint', align: 'center', sortable: false, value: 'tempo', width: '75' },
        { text: 'Intervalo entre Sprints', align: 'center', sortable: false, value: 'intervalo_tiros', width: '150' },
        { text: 'Freq Cardíaca', align: 'center', sortable: false, value: 'freqCardiaca', width: '100' },
        { text: 'Hiit', align: 'center', sortable: false, value: 'hiit', width: '50' },
        { text: 'Aeróbico Continuado', align: 'center', sortable: false, value: 'aerobicocont', width: '100' },
        { text: '', align: 'left', sortable: false, value: 'actions', width: '90' }
      ],
      ManobrasContHeader: [
        { text: '', align: 'left', sortable: false, value: 'draggable', width: '50' },
        { text: 'Exercício', align: 'left', sortable: false, value: 'exercise.name', width: '350' },
        { text: 'Repetições em Isometria', align: 'center', sortable: false, value: 'quantidade_tiros', width: '130' },
        { text: 'Tempo de Cada Repetição', align: 'center', sortable: false, value: 'tempo', width: '170' },
        { text: 'Intervalo entre Cada Repetição', align: 'center', sortable: false, value: 'intervalo_tiros', width: '170' },
        { text: '', align: 'left', sortable: false, value: 'actions', width: '300' }
      ]
    }
  },
  beforeMount () {
    this.setCycleType('meso')
  },
  methods: {
    ...mapActions('base', ['fetchPdf']),
    ...mapActions('students', [
      'setTrainingDialog',
      'destroyTraining',
      'setTrainingIndex',
      'setCreateOrUpdateExerciseDialog',
      'destroyExercise',
      'setCreateOrUpdateExercise',
      'setCreateOrUpdateExerciseIndex',
      'setCycleType'
    ]),
    ...mapActions('base', ['setSnackbar', 'setLoader']),
    newExerciseDialog (trainingIndex, exercise) {
      this.setTrainingIndex(trainingIndex)
      this.setCreateOrUpdateExercise(exercise)
      const exerciseIndex = this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises.indexOf(exercise)
      this.setCreateOrUpdateExerciseIndex(exerciseIndex)
      this.setCreateOrUpdateExerciseDialog(false)
      this.setCreateOrUpdateExerciseDialog(true)
    },
    loadMesocycle () {
      this.setLoader({ show: true })
      var idstudent = this.student.id

      if (!this.thisStudent) {
        idstudent = this.studentcopy
        StudentService.get(idstudent)
          .then(({ data }) => {
            this.studentSel = data.student.name
            this.callMesocile(idstudent)
          })
          .catch(({ response }) => {
            this.setLoader({ show: false })
            this.setSnackbar({
              color: 'error',
              show: true,
              message: 'Aluno Não Encontrado'
            })
          })
      } else {
        this.studentSel = this.student.name
        this.callMesocile(idstudent)
      }
    },
    clearStudent () {
      if (this.thisStudent) {
        this.studentcopy = ''
        this.mesocycles = null
        this.studentSel = this.student.name
      }
    },
    callMesocile (idstudent) {
      console.log(idstudent)
      MesocycleService.getmesocyclesList(idstudent)
        .then(({ data }) => {
          this.mesocycles = data.mesocycles.filter(mesocycle => !mesocycle.weeks && mesocycle.id !== this.mesocycleToBeEvaluatedId && mesocycle.trainings.length > 0)
          this.setLoader({ show: false })
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Mesociclos Carregados'
          })
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
          this.setLoader({ show: false })
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Erro ao Pesquisar Mesociclos'
          })
        })
    },
    newTraining () {
      this.createOrUpdateTrainingDialog = true
      this.currentTraining = Object.assign({}, {})
      this.currentTrainingIndex = -1
    },
    copyTraining () {
      this.setLoader({ show: true })
      try {
        if (!this.student.id || !this.mesocycleToBeEvaluated.id || !this.mesoCopy.id) {
          throw new Error('ID do estudante, mesociclo ou mesociclo para cópia não definido.')
        }
        MesocycleService.selectItemToCopy(this.student.id, this.mesocycleToBeEvaluated.id, this.mesoCopy.id)
        this.setSnackbar({
          show: true,
          color: 'success',
          message: 'Treino Copiado Com Sucesso!!'
        })
      } catch (error) {
        this.setSnackbar({
          show: true,
          color: 'error',
          message: 'Erro: ' + error.message
        })
      } finally {
        this.setLoader({ show: false })
        this.closeDialog()
      }
    },
    editTraining (training, index) {
      this.createOrUpdateTrainingDialog = true
      this.currentTraining = Object.assign({}, training)
      this.currentTrainingIndex = index
    },
    closeDialog () {
      this.setTrainingDialog(false)
      this.currentTraining = {}
      this.createOrUpdateTrainingDialog = false
      this.$emit('updateStudantData')
    },
    update (successMessage, failMessage) {
      this.$emit('update', { successMessage, failMessage })
    },
    selectTrainingToBeDestroyed (idx) {
      this.confirmationDialog = true
      this.trainingToBeDestroyedIndex = idx
    },
    destroy () {
      this.setLoader({ show: true })
      MesocycleService.removeTraining(this.mesocycleToBeEvaluated.id, this.trainingToBeDestroyedIndex)
        .then(() => {
          this.destroyTraining({ trainingIndex: this.trainingToBeDestroyedIndex, cycle: 'meso' })
          this.setSnackbar({
            show: true,
            color: 'success',
            message: 'Treino removido com sucesso'
          })
          this.closeConfirmationDialog()
        })
        .catch(({ response }) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    closeConfirmationDialog () {
      this.confirmationDialog = false
    },
    openConfirmationDestroyExerciseDialog (exercise) {
      this.exerciseToBeDestroyed = exercise
      this.confirmationDestroyExerciseDialog = true
    },
    closeConfirmationDestroyExerciseDialog () {
      this.exerciseToBeDestroyed = null
      this.confirmationDestroyExerciseDialog = false
    },
    openConfirmationCopyExerciseDialog (exercise, index) {
      this.currentTrainingIndex = index
      this.exerciseToBeCopied = exercise
      this.confirmationCopyExerciseDialog = true
    },
    closeConfirmationCopyExerciseDialog () {
      this.currentTrainingIndex = -1
      this.exerciseToBeCopied = null
      this.confirmationCopyExerciseDialog = false
    },
    getExercisesByType (idx) {
      const exercises = []
      if (this.stretchingExercises(idx) && this.stretchingExercises(idx).length) {
        exercises.push({ title: 'Alongamentos', headers: this.stretchingHeader, items: this.stretchingExercises(idx) })
      }
      if (this.mobilityExercises(idx) && this.mobilityExercises(idx).length) {
        exercises.push({ title: 'Mobilidade', headers: this.mobilityHeader, items: this.mobilityExercises(idx) })
      }
      if (this.myofascialReleaseExercises(idx) && this.myofascialReleaseExercises(idx).length) {
        exercises.push({ title: 'Liberação Miofascial', headers: this.myofascialReleaseHeader, items: this.myofascialReleaseExercises(idx) })
      }
      if (this.functionalExercises(idx) && this.functionalExercises(idx).length) {
        exercises.push({ title: 'Exercícios Funcionais', headers: this.functionalHeader, items: this.functionalExercises(idx) })
      }
      if (this.resistanceExercises(idx) && this.resistanceExercises(idx).length) {
        exercises.push({ title: 'Treinos Resistidos', headers: this.resistanceHeader, items: this.resistanceExercises(idx) })
      }
      if (this.respExercises(idx) && this.respExercises(idx).length) {
        exercises.push({ title: 'Manobras Respiratórias/core', headers: this.ManobrasContHeader, items: this.respExercises(idx) })
      }
      if (this.respaerobic(idx) && this.respaerobic(idx).length) {
        exercises.push({ title: 'Exercícios Aeróbicos', headers: this.AerobicoContHeader, items: this.respaerobic(idx) })
      }
      return exercises
    },
    removeExercise () {
      const exerciseIndex = this.mesocycleToBeEvaluated.trainings[this.tabs].exercises.findIndex(exercise => {
        if (Array.isArray(exercise.exercise)) {
          return exercise.exercise.some(ex =>
            ex.id === (Array.isArray(this.exerciseToBeDestroyed.exercise)
              ? this.exerciseToBeDestroyed.exercise[0].id
              : this.exerciseToBeDestroyed.exercise.id)
          )
        } else {
          return exercise.exercise.id === (Array.isArray(this.exerciseToBeDestroyed.exercise)
            ? this.exerciseToBeDestroyed.exercise[0].id
            : this.exerciseToBeDestroyed.exercise.id)
        }
      })

      if (exerciseIndex >= 0) {
        this.destroyExercise({ trainingIndex: this.tabs, exerciseIndex })
        this.update('Exercício removido com sucesso', 'Erro ao remover Exercício')
      } else {
        console.error('Exercício não encontrado para deleção:', this.exerciseToBeDestroyed)
        this.setSnackbar({
          color: 'error',
          show: true,
          message: 'Erro: Exercício não encontrado para remoção'
        })
      }
      this.closeConfirmationDestroyExerciseDialog()
    },
    downloadCurrentTrainingPdf () {
      if (!this.mesocycleToBeEvaluated || !this.mesocycleToBeEvaluated.trainings || this.mesocycleToBeEvaluated.trainings.length === 0) {
        this.setSnackbar({
          color: 'error',
          show: true,
          message: 'Erro: Nenhum treino disponível para download.'
        })
        this.setLoader({ show: false })
        return
      }

      const payload = {
        studentId: this.mesocycleToBeEvaluated.macrocycle.student_id,
        macrocycleId: this.mesocycleToBeEvaluated.macrocycle.id,
        mesocycleId: this.mesocycleToBeEvaluated.id
      }

      const trainingName = this.mesocycleToBeEvaluated.trainings && this.mesocycleToBeEvaluated.trainings.length > 0
        ? this.mesocycleToBeEvaluated.trainings[this.tabs]?.name || 'sem nome'
        : 'sem nome'
      const tag = Object.values(payload).join('.')
      const fileName = `${tag} - ${trainingName}.pdf`

      this.setLoader({ show: true })
      this.fetchPdf({ service: DocumentService.getTrainingPdf, payload, fileName })
        .then(() => {
          console.log('PDF baixado com sucesso')
        })
        .catch(error => {
          console.error('Erro ao baixar PDF:', error)
          const errorMessage = error.response && error.response.data && error.response.data.errors && error.response.data.errors.detail
            ? error.response.data.errors.detail
            : error.message || 'Erro desconhecido ao baixar PDF'
          this.setSnackbar({
            color: 'error',
            show: true,
            message: `Erro ao baixar PDF: ${errorMessage}`
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    isEmptyWeekdayTraining (weekday, trainings) {
      try {
        if (!Array.isArray(trainings) || trainings.length === 0) {
          return true
        }

        const idx = trainings.findIndex(training => {
          return Array.isArray(training.weekdays) && training.weekdays.some(w => Number(w) === weekday.id)
        })
        return idx === -1
      } catch (error) {
        return true
      }
    },
    getMuscleGroupSeriesFromWeekday (weekday, trainings) {
      var muscleGroups = {}
      var idx
      try {
        if (!Array.isArray(trainings) || trainings.length === 0) {
          return { name: 'Sem Treino', worked_muscle_groups: [], exercisesCount: 0 }
        }
        idx = trainings.findIndex(training => {
          return Array.isArray(training.weekdays) && training.weekdays.some(w => Number(w) === weekday.id)
        })

        if (idx === -1) {
          return { name: 'Sem Treino', worked_muscle_groups: [], exercisesCount: 0 }
        }

        const training = trainings[idx]

        training.exercises.forEach(trainingExercise => {
          const exerciseArray = Array.isArray(trainingExercise.exercise) ? trainingExercise.exercise : [trainingExercise.exercise]
          exerciseArray.forEach(ex => {
            if (ex && Array.isArray(ex.muscle_group)) {
              ex.muscle_group.forEach(muscleGroup => {
                const exerciseMuscleGroup = muscleGroup.name
                if (!muscleGroups[exerciseMuscleGroup]) {
                  muscleGroups[exerciseMuscleGroup] = 0
                }
                if (ex.exercise_type === 'resistence') {
                  muscleGroups[exerciseMuscleGroup] += parseInt(trainingExercise.series) || 1
                }
              })
            }
          })
        })

        const workedMuscleGroups = Object.keys(muscleGroups).map(muscleGroup => ({
          name: muscleGroup,
          seriesAmount: muscleGroups[muscleGroup]
        }))

        return {
          name: training.name,
          worked_muscle_groups: workedMuscleGroups.filter(item => item.seriesAmount > 0),
          exercisesCount: training.exercises.length
        }
      } catch (error) {
        return {
          name: trainings[idx] ? trainings[idx].name : 'ERRO',
          worked_muscle_groups: [],
          exercisesCount: 0
        }
      }
    },
    getAlongamentosFromWeekday (weekday, trainings) {
      var alongamentos = 0
      var idx
      try {
        idx = trainings.findIndex(training => {
          return training.weekdays.findIndex(w => w === weekday.id) !== -1
        })

        trainings[idx].exercises.forEach(trainingExercise => {
          if (trainingExercise.exercise.exercise_type === 'resistence') {
            alongamentos += parseInt(trainingExercise.series) || 1
          }
        })

        return alongamentos
      } catch (error) {
        return 0
      }
    }
  },
  mounted () {
    if (this.thisStudent) {
      this.loadMesocycle()
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'studentTrainingDialog', 'mesocycleToBeEvaluated']),
    ...mapGetters('auth', ['user']),
    mesocycleToBeEvaluatedId () {
      return this.mesocycleToBeEvaluated.id
    },
    aerobicExercises () {
      return trainingIndex => {
        try {
          return filter(this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises,
            (item) => item.exercise.exercise_type === 'aerobic') || []
        } catch (error) {
          return []
        }
      }
    },
    hiitExercises () {
      return trainingIndex => {
        try {
          return filter(this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises,
            (item) => item.exercise.exercise_type === 'hiit') || []
        } catch (error) {
          return []
        }
      }
    },
    stretchingExercises () {
      return trainingIndex => {
        try {
          return this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises
            .filter(item => {
              if (Array.isArray(item.exercise)) {
                return item.exercise.every(ex => ex.exercise_type === 'stretching')
              }
              return item.exercise && item.exercise.exercise_type === 'stretching'
            }) || []
        } catch (error) {
          return []
        }
      }
    },
    mobilityExercises () {
      return trainingIndex => {
        try {
          return this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises
            .filter(item => {
              if (Array.isArray(item.exercise)) {
                return item.exercise.every(ex => ex.exercise_type === 'mobility')
              }
              return item.exercise && item.exercise.exercise_type === 'mobility'
            }) || []
        } catch (error) {
          return []
        }
      }
    },
    myofascialReleaseExercises () {
      return trainingIndex => {
        try {
          return this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises
            .filter(item => {
              if (Array.isArray(item.exercise)) {
                return item.exercise.every(ex => ex.exercise_type === 'myofascial_release')
              }
              return item.exercise && item.exercise.exercise_type === 'myofascial_release'
            }) || []
        } catch (error) {
          return []
        }
      }
    },
    resistanceExercises () {
      return trainingIndex => {
        try {
          return this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises
            .filter(item => {
              if (Array.isArray(item.exercise)) {
                return item.exercise.every(ex => ex.exercise_type === 'resistence')
              }
              return item.exercise && item.exercise.exercise_type === 'resistence'
            }) || []
        } catch (error) {
          console.error('Erro ao filtrar exercícios resistidos:', error)
          return []
        }
      }
    },
    functionalExercises () {
      return trainingIndex => {
        try {
          return this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises
            .filter(item => {
              if (Array.isArray(item.exercise)) {
                return item.exercise.every(ex => ex.exercise_type === 'functional')
              }
              return item.exercise && item.exercise.exercise_type === 'functional'
            }) || []
        } catch (error) {
          return []
        }
      }
    },
    respExercises () {
      return trainingIndex => {
        try {
          return this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises
            .filter(item => {
              if (Array.isArray(item.exercise)) {
                return item.exercise.every(ex => ex.exercise_type === 'manobra_resp')
              }
              return item.exercise && item.exercise.exercise_type === 'manobra_resp'
            }) || []
        } catch (error) {
          return []
        }
      }
    },
    respaerobic () {
      return trainingIndex => {
        try {
          return this.mesocycleToBeEvaluated.trainings[trainingIndex].exercises
            .filter(item => {
              if (Array.isArray(item.exercise)) {
                return item.exercise.every(ex => ex.exercise_type === 'exerc_aerobicos')
              }
              return item.exercise && item.exercise.exercise_type === 'exerc_aerobicos'
            })
            .map(v => ({
              ...v,
              aerobicocont: v.aerobicocont ? 'Sim' : 'Não'
            })) || []
        } catch (error) {
          return []
        }
      }
    },
    mesocycleIsFinished () {
      const now = new Date()
      const endDate = new Date(`${this.mesocycleToBeEvaluated.end_date} 23:59:59`)
      return endDate < now
    },
    isEmptyState () {
      try {
        return !(this.mesocycleToBeEvaluated.trainings && this.mesocycleToBeEvaluated.trainings.length > 0)
      } catch (error) {
        return true
      }
    },
    isStudent () {
      return this.user.isStudent()
    },
    readonly () {
      return this.mesocycleIsFinished || this.isStudent
    }
    // consoleLogMasocycle () {
    //   console.log(this.mesocycleToBeEvaluated)
    // }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1264px;
  }
}

.lightgrey {
  background-color: #E6E6E6;
}

.training-tabs {
  border-bottom: solid grey 1px;

  .v-tab--active {
    color: grey !important;
  }
}
</style>
