<template>
  <v-dialog :value="loginDialog.show" width="680px" persistent>
    <v-row no-gutters>
      <v-col class="login-background pa-0" cols="5">
        <v-row class="align-center justify-end" style="height: 100%;">
          <Logo imgColor="white" :height="36" />
        </v-row>
      </v-col>

      <v-col class="pa-0 white">
        <v-row class="align-center pa-6 mx-0">
          <template v-if="!isPasswordReset">
            <v-card
              elevation="0"
              width="338px"
              height="460px"
            >
              <v-card-title> Sua sessão expirou </v-card-title>
              <v-card-subtitle> Para continuar realize o login novamente.</v-card-subtitle>

              <v-card-text>
                <v-text-field
                  v-model="email"
                  color="secondary"
                  label="Email"
                  filled
                  dense
                />
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' :  'password'"
                  @keyup.enter="login"
                  color="secondary"
                  label="Senha"
                  filled
                  dense
                />

                <v-btn block color="primary" @click="login">
                  Entrar
                </v-btn>
                <v-checkbox
                  v-model="students"
                  color="secondary"
                  label="Sou Aluno"
                />

                <v-btn block class="mt-2"
                  color="blue darken-2"
                  text small
                  @click="isPasswordReset = true"
                >
                  Esqueci a senha
                </v-btn>

                <v-divider class="my-4"></v-divider>

                <v-row class="justify-center mx-0">
                  <v-col cols="auto">Não quer continuar na aplicação?</v-col>
                  <v-col cols="12">
                    <v-btn outlined block color="primary" @click="exit()"> Sair </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>

          <template v-else>
            <v-card
              elevation="0"
              width="338px"
              height="460px"
            >
              <v-btn class="mt-2 ml-2" text @click="isPasswordReset = false"> <v-icon left>mdi-arrow-left</v-icon> Voltar</v-btn>

              <v-card-title> Resetar senha </v-card-title>

              <v-card-text>
                <v-text-field
                  v-model="email"
                  color="secondary"
                  label="Email"
                  filled
                  dense
                  @keyup.enter="resetPasswordInstructions"
                />

                <v-btn block color="primary" @click="resetPasswordInstructions">
                  Enviar instruções
                </v-btn>
              </v-card-text>
            </v-card>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import userAuthenticationService from '@/services/authentication-service'
import adminAuthenticationService from '@/services/admin-authentication-service'
import axiosInstance from '@/services/axios-instance'
import axiosNodeInstance from '@/services/axios-node-instance'
import { mapActions, mapGetters, mapState } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'login',

  props: {
    mode: String
  },

  components: {
    Logo
  },

  data: () => ({
    showPassword: false,
    email: '',
    password: '',
    students: false,
    resetPasswordEmail: '',
    isPasswordReset: false
  }),

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('base', ['loginDialog']),
    authenticationService () {
      return this.mode === 'Admin' ? adminAuthenticationService : userAuthenticationService
    }
  },

  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar', 'resetLoginDialog']),
    ...mapActions('auth', ['activeCheckSession', 'fetchCurrentUser']),
    login () {
      this.setLoader({ show: true })
      const payload = {
        email: this.email.toLowerCase(),
        password: this.password,
        students: false
      }
      this.authenticationService.login(payload)
        .then(({ data }) => {
          localStorage.setItem('access_token', data.token)
          this.setCookie(data.token)
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`
          axiosNodeInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`
          this.activeCheckSession()

          this.loginDialog.onSuccess.forEach(promise => promise())
          this.fetchCurrentUser()

          this.resetLoginDialog({ show: false })
        })
        .catch((request) => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: request.response.data.errors.detail
          })
          if (request.response.status === 402) {
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${request.response.data.token}`
            axiosNodeInstance.defaults.headers.common.Authorization = `Bearer ${request.response.data.token}`
            this.$router.push({ name: 'RenewPlan' })
          }
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },

    resetPasswordInstructions () {
      this.setLoader({ show: true })
      this.authenticationService.resetPasswordInstructions({ email: this.email.toLowerCase() })
        .finally(() => {
          this.setSnackbar({
            show: true,
            message: 'Por favor, siga as instruções enviadas no email!'
          })
          this.isPasswordReset = false
          this.setLoader({ show: false })
        })
    },

    exit () {
      this.setLoader({ show: false })
      this.resetLoginDialog({ show: false })

      if (this.mode === 'Admin') {
        return this.$router.push('/admin')
      }
      return this.$router.push('/')
    },
    setCookie (token) {
      this.$cookies.set('access_token', token)
    }
  }
}
</script>

<style scoped>
  .login-background {
    background-image: url('../../assets/images/login-background.png');
    background-size: cover;
  }
</style>
