<template>
  <v-row class="mainContainer">
    <v-col class="login-background pa-0" lg="5" md="5" sm="5" xs="12">
      <v-row class="align-center justify-end" style="height: 100%">
        <Logo imgColor="white" />
      </v-row>
    </v-col>
    <v-col class="mainContent">
      <v-row class="responsive-line" style="height: 100%">
        <v-card elevation="0" class="responsive-card">
          <v-card-title class="">Entrar</v-card-title>
          <v-card-text>
            <v-text-field v-model="email" color="secondary" label="Email" filled dense />
            <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword" :type="showPassword ? 'text' : 'password'"
              @keyup.enter="login" color="secondary" label="Senha" filled dense />
            <v-checkbox v-model="students" color="secondary" label="Sou Aluno" />

            <v-btn block color="primary" @click="login">Entrar</v-btn>
            <v-row class="responsive-actions">
              <v-col cols="auto" style="margin: 0; padding: 0;">
                <v-dialog width="480" v-model="dialog">
                  <template v-slot:activator="{ on }">
                    <v-btn color="blue darken-2" text small v-on="on">Esqueci a senha</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline primary white--text" primary-title>
                      Resetar a senha
                    </v-card-title>
                    <v-card-text>
                      <div>
                        <span>Digite seu e-mail</span>
                      </div>
                      <div>
                        <v-text-field v-model="resetPasswordEmail" color="secondary" label="Email" hide-details filled
                          dense />
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" text @click="resetPasswordInstructions">Enviar instruções</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="auto" style="margin: 0; padding: 0;">
                <v-dialog width="480" v-model="codeDialog" persistent>
                  <v-card>
                    <v-card-title class="headline primary white--text" primary-title>
                      Confirme o Código
                    </v-card-title>
                    <v-card-text>
                      <div>
                        <span>Informe o código enviado pelo WhatsApp:</span>
                      </div>
                      <div>
                        <v-text-field v-model="verificationCode" color="secondary" label="Código" hide-details filled dense />
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="error" @click="codeDialog = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="blue darken-2" @click="newCode">Novo Código</v-btn>
                      <v-btn color="primary" @click="confirmCode">Enviar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="auto" style="margin: 0; padding: 0;">
                <v-dialog width="1024" v-model="showTermsDialog">
                  <template v-slot:activator="{ on }">
                    <v-btn color="blue darken-2" text small v-on="on">Termos de Uso</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline primary white--text" primary-title style="margin-top: -10px;">
                      Termos de Uso - FisicSoft
                    </v-card-title>
                    <v-card-text>
                      Termo de Uso da Aplicação Web "FisicSoft"<br><br>

                      Última atualização: 10 de outubro de 2023<br><br>

                      Bem-vindo ao "FisicSoft", uma aplicação web dedicada ao acompanhamento de exercícios, cadastro de
                      exercícios,
                      avaliações físicas e avaliações posturais. Agradecemos por escolher nossa plataforma. Antes de
                      utilizar os
                      serviços oferecidos pelo FisicSoft, é fundamental que você leia, compreenda e concorde com os
                      termos e
                      condições
                      aqui estabelecidos. Este Termo de Uso ("Termo") estabelece os direitos e deveres tanto do usuário
                      quanto da equipe
                      do FisicSoft.
                      <br><br>
                      Aceitação dos Termos de Uso<br><br>
                      Ao utilizar a aplicação web FisicSoft, você concorda integralmente com este Termo de Uso e com a
                      nossa
                      Política de
                      Privacidade. Se você não concordar com os termos aqui estabelecidos, não deve utilizar a
                      aplicação.
                      <br><br>
                      Cadastro de Usuário<br><br>
                      2.1. Para utilizar todos os recursos do FisicSoft, você deve criar uma conta de usuário. Ao fazer
                      o
                      cadastro, você
                      concorda em fornecer informações verdadeiras, precisas e atualizadas.
                      <br><br>
                      2.2. Você é responsável pela confidencialidade da sua senha e conta, e concorda em notificar
                      imediatamente o
                      FisicSoft caso suspeite de uso não autorizado da sua conta.
                      <br><br>
                      Uso da Aplicação<br><br>
                      3.1. O FisicSoft é uma plataforma destinada ao acompanhamento de exercícios, cadastro de
                      exercícios,
                      avaliações
                      físicas e avaliações posturais. Você concorda em usar a aplicação de acordo com seus propósitos
                      legítimos e
                      éticos.
                      <br><br>
                      3.2. Você é responsável por todas as atividades realizadas em sua conta, incluindo o conteúdo que
                      você
                      criar,
                      compartilhar ou armazenar na plataforma.
                      <br><br>
                      Privacidade<br><br>
                      4.1. Respeitamos a sua privacidade. Nossas práticas de coleta e uso de dados são regidas pela
                      nossa
                      Política de
                      Privacidade, que está disponível na aplicação e deve ser lida e compreendida por você.
                      <br><br>
                      Conteúdo do Usuário<br><br>
                      5.1. Você é o único responsável pelo conteúdo que cria, envia, compartilha ou armazena na
                      aplicação.
                      Você deve
                      garantir que seu conteúdo esteja em conformidade com as leis aplicáveis e não viole direitos de
                      terceiros.
                      <br><br>
                      Direitos Autorais e Propriedade Intelectual<br><br>
                      6.1. Você concorda em respeitar os direitos autorais e outros direitos de propriedade intelectual
                      de
                      terceiros.
                      Não é permitida a distribuição ou reprodução de conteúdo protegido por direitos autorais sem a
                      devida
                      autorização.
                      <br><br>
                      Modificações e Interrupção do Serviço<br><br>
                      7.1. O FisicSoft se reserva o direito de modificar, suspender ou encerrar o serviço, total ou
                      parcialmente, a
                      qualquer momento e sem aviso prévio.
                      <br><br>
                      Limitação de Responsabilidade<br><br>
                      8.1. O FisicSoft não se responsabiliza por quaisquer danos diretos, indiretos, incidentais,
                      especiais
                      ou
                      consequentes decorrentes do uso ou incapacidade de uso da aplicação.
                      <br><br>
                      Lei Aplicável e Jurisdição<br><br>
                      9.1. Este Termo de Uso é regido pelas leis do Brasil, e qualquer litígio decorrente ou relacionado
                      a
                      este Termo
                      será submetido à jurisdição dos tribunais competentes do Brasil.
                      <br><br>
                      Contato<br><br>
                      10.1. Se você tiver dúvidas ou preocupações relacionadas a este Termo de Uso, entre em contato
                      conosco
                      pelo e-mail
                      [inserir endereço de e-mail de contato].
                      <br><br>
                      Ao utilizar o FisicSoft, você declara que leu, compreendeu e concordou com este Termo de Uso e com
                      a
                      Política de
                      Privacidade. Caso não concorde com qualquer parte destes termos, por favor, não continue a
                      utilizar
                      nossa
                      aplicação.
                      <br><br>
                      Agradecemos por escolher o FisicSoft como sua plataforma de acompanhamento de exercícios e
                      avaliações
                      físicas.
                      Desejamos uma experiência agradável e produtiva!
                      <br><br>
                      Atenciosamente,
                      <br><br>
                      Equipe FisicSoft
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" @click="showTermsDialog = false">Fechar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import authenticationService from '@/services/authentication-service'
import axiosInstance from '@/services/axios-instance'
import axiosNodeInstance from '@/services/axios-node-instance'
import { mapActions, mapGetters } from 'vuex'
import Logo from '@/components/base/Logo'

export default {
  name: 'login',
  components: {
    Logo
  },
  data: () => ({
    showPassword: false,
    email: '',
    password: '',
    students: false,
    resetPasswordEmail: '',
    dialog: false,
    showTermsDialog: false,
    codeDialog: false,
    verificationCode: ''
  }),
  computed: {
    ...mapGetters('auth', ['user'])
  },
  watch: {
    '$route.query': {
      handler (to) {
        if (to.policy === 'privacy') {
          this.showTermsDialog = true
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('auth', ['fetchCurrentUser']),
    login () {
      this.setLoader({ show: true })
      const payload = {
        email: this.email.toLowerCase().trim(),
        password: this.password,
        students: this.students,
        version: '1.0.3'
      }
      authenticationService
        .login(payload)
        .then(({ data }) => {
          localStorage.setItem('access_token', data.token)
          this.setCookie(data.token)
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`
          axiosNodeInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`
          this.fetchCurrentUser()
            .then(() => {
              this.$router.push({ name: 'HomeLayout' })
            })
            .catch(error => {
              console.error('Erro ao buscar usuário:', error)
            })
        })
        .catch(error => {
          if (error.response.status === 402) {
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${error.response.data.token}`
            axiosNodeInstance.defaults.headers.common.Authorization = `Bearer ${error.response.data.token}`
            this.setCookie(error.response.data.token)
            this.$router.push({ name: 'RenewPlan' })

            this.setSnackbar({
              color: 'error',
              show: true,
              message: 'Há um problema com sua assinatura'
            })
          } else if (error.response.status === 422 && payload.students) {
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${error.response.data.token}`
            axiosNodeInstance.defaults.headers.common.Authorization = `Bearer ${error.response.data.token}`
            this.setCookie(error.response.data.token)
            this.$router.push({ name: 'ConfirmCodeStudent', query: { email: payload.email, students: payload.students } })
          } else {
            this.setSnackbar({
              color: 'error',
              show: true,
              message: error.response.data.errors.detail
            })
          }
        })
        .finally(() => {
          this.setLoader({ show: false })
        })
    },
    resetPasswordInstructions () {
      this.setLoader({ show: true })
      authenticationService
        .resetPasswordInstructions({ email: this.resetPasswordEmail.toLowerCase() })
        .then(({ data }) => {
          if (data?.role_type) {
            this.students = data.role_type === 'Student'
            this.codeDialog = true
          } else {
            this.resetPasswordEmail = ''
            this.setSnackbar({
              color: 'success',
              show: true,
              message: 'Por favor, siga as instruções enviadas no email!'
            })
          }
        })
        .catch(error => {
          this.setSnackbar({
            color: 'error',
            show: true,
            message: error.response.data.errors.detail
          })
        })
        .finally(() => {
          this.setLoader({ show: false })
          this.dialog = false
        })
    },
    confirmCode () {
      this.setLoader({ show: true })
      if (!this.verificationCode.trim()) {
        this.setSnackbar({
          color: 'error',
          show: true,
          message: 'Por favor, informe o código de verificação.'
        })
        return
      }

      const payload = {
        email: this.resetPasswordEmail.toLowerCase(),
        role_type: this.students ? 'Student' : 'PersonalTrainer',
        code: this.verificationCode.trim()
      }
      authenticationService.confirmResetPasswordCode(payload)
        .then((response) => {
          if (response.data?.success) {
            this.$router.push({ name: 'ResetPassword', query: { token: response.data.token } })
          }
        })
        .catch((err) => {
          this.msg = 'Informe o código enviado para seu whatsapp.'
          this.setSnackbar({
            color: 'error',
            show: true,
            message: err.response?.data?.errors.detail || 'Houve um imprevisto. Por favor, tente novamente mais tarde.'
          })
        })
        .finally(() => {
          setInterval(() => {
            this.setLoader({ show: false })
          }, 200)
        })
    },
    newCode () {
      this.setLoader({ show: true })
      authenticationService
        .resetPasswordInstructions({ email: this.resetPasswordEmail.toLowerCase() })
        .then((response) => {
          this.setSnackbar({
            color: 'success',
            show: true,
            message: 'Um novo código de confirmação foi enviado para você!'
          })
        })
        .catch((err) => {
          console.log(err)
          this.setSnackbar({
            color: 'error',
            show: true,
            message: 'Não foi possível enviar o novo código. Por favor, entre em contato com o suporte.'
          })
        })
        .finally(() => {
          setInterval(() => {
            this.setLoader({ show: false })
          }, 200)
        })
    },
    setCookie (token) {
      this.$cookies.set('access_token', token)
    }
  }
}
</script>

<style scoped>
.login-background {
  height: 100vh;
  background-image: url('../../assets/images/login-background.png');
  background-size: cover;
}

.mainContainer {
  height: 100vh;
  display: flex;
}

.responsive-actions {
  margin-top: 16px;
  justify-content: space-between;
}

.responsive-card {
  width: 40%;
  margin-left: 12%;
  margin-top: 48px;
}

.responsive-line {
  align-items: center;
  justify-content: start;
}

@media (min-width: 1100px) and (max-width: 1400px) {

  .responsive-line {
    justify-content: center;
  }

  .responsive-card {
    width: 70%;
    margin: 0 5%;
    margin-left: 6%;
    margin-top: 48px;

  }
}

@media (max-width: 1099px) {

  .responsive-line {
    align-items: center;
    justify-content: center;
  }

  .responsive-card {
    width: 100%;
    margin: 0 5%;
    margin-top: 48px;

  }
}

@media (max-width: 600px) {
  .mainContainer {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .login-background .row {
    margin: 0;
  }

  .login-background img {
    width: 100%;
  }

  .mainContent {
    flex-grow: 3;
  }

  .responsive-card {
    width: 90%;
    margin: 0;
    margin-top: 24px;
    padding: 0;
  }
}
</style>
