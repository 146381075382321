<template>
  <v-container class="py-5 px-0">

    <v-card v-if="isStudent">
      <v-card-title class="headline secondary white--text" primary-title>
        Galeria de Imagens Posturais
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-tabs>
            <v-tab>
              Principal
            </v-tab>
            <v-tab>
              Opcional
            </v-tab>

            <v-tab-item class="pa-2 px-0">
              <v-row>
                <v-col cols="3" v-for="(title, objectKey) in files.principais" :key="objectKey">
                  <preview-upload-photo-card class="elevation-6" :title="title"
                    :editImage="getPhotoSrc('principais', objectKey)" :objectKey="objectKey" />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="pa-2 px-0">
              <v-row>
                <v-col cols="3" v-for="(title, objectKey) in files.opcionais" :key="objectKey">
                  <preview-upload-photo-card class="elevation-6" :title="title"
                    :editImage="getPhotoSrc('opcionais', objectKey)" :objectKey="objectKey" />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

    </v-card>

    <v-row v-if="!isStudent">
      <v-col cols=6 class="pr-2" v-if="!isStudent">
        <v-expansion-panels focusable>
          <v-expansion-panel v-for="(item, i) in items" :key="i">
            <v-expansion-panel-header>
              <span class="subtitle-2 text-uppercase"> {{ i }} </span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row>
                <v-col v-for="(selects, j) in item" :key="j" :cols="item.length > 1 ? 6 : 12">
                  <span class="text-uppercase font-weight-bold caption" v-if="item.length > 1 && j == 0">Esquerdo</span>
                  <span class="text-uppercase font-weight-bold caption" v-if="item.length > 1 && j == 1">Direito</span>
                  <v-row v-for="(prop, k) in selects" :key="k" class="px-2">
                    <v-select v-model="prop.selected" :items="prop.items" @input="updateItems()"
                      @blur="$emit('updateMesocycleEvaluation');" :disabled="disableInput" placeholder="Selecionar" />
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col cols=6 class="pl-2">
        <v-row class="justify-end px-3 pb-3">
          <v-tabs v-model="photoSelector" @change="carousel = 0" style="width: 400px">
            <v-tab>
              Principais
            </v-tab>
            <v-tab>
              Opcionais
            </v-tab>
          </v-tabs>
          <v-dialog v-model="dialogPhotos" fullscreen persistent :key="mesocycleToBeEvaluated.id" style="z-index: 995">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on">
                <v-icon class="mr-2">
                  mdi-image-plus
                </v-icon>
                Carregar Fotos
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline secondary white--text" primary-title>
                Galeria de Imagens Posturais
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-tabs>
                    <v-tab>
                      Principal
                    </v-tab>
                    <v-tab>
                      Opcional
                    </v-tab>

                    <v-tab-item class="pa-2 px-0">
                      <v-row>
                        <v-col xs="12" sm="6" md="4" lg="3" xl="2" v-for="(title, objectKey) in files.principais"
                          :key="objectKey">
                          <preview-upload-photo-card class="elevation-6" :title="title"
                            :editImage="getPhotoSrc('principais', objectKey)" :objectKey="objectKey" />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item class="pa-2 px-0">
                      <v-row>
                        <v-col cols="3" v-for="(title, objectKey) in files.opcionais" :key="objectKey">
                          <preview-upload-photo-card class="elevation-6" :title="title"
                            :editImage="getPhotoSrc('opcionais', objectKey)" :objectKey="objectKey" />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>
                </v-container>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialogPhotos = false">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <v-carousel v-model="carousel" height="400" hide-delimiters style="margin-bottom: 20px;">

          <v-btn v-if="!isStudent" style="z-index: 9999;" absolute dark top left class="grey darken-4"
            @click="visible = !visible" icon size="large">
            <v-icon v-if="visible">mdi-eye</v-icon>
            <v-icon v-else>mdi-eye-off</v-icon>
          </v-btn>

          <v-btn style="z-index: 9999;" absolute bottom right dark class="grey darken-4 mr-11" @click="zoomToggler(0.1)"
            icon size="large">
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>

          <v-btn style="z-index: 9999;" absolute bottom right dark class="grey darken-4" @click="zoomToggler(-0.1)" icon
            size="large">
            <v-icon>mdi-magnify-minus-outline</v-icon>
          </v-btn>
          <v-carousel-item v-for="(photo, i) in photos" :key="`photo-postural-${i}`">
            <draggable-grade :visible="carousel === i" :hasContent="true" :ref="`zoom${i}`">
              <v-img :src="photo.src" height="380" contain />
            </draggable-grade>
          </v-carousel-item>

          <draggable-grade v-if="!isStudent" :visible="visible" ref="zoomG" :hasContent="true" :zoomBase="2.0">
            <v-img :src="require('@/assets/images/postura/simetrograph.png')" height="100%" style="opacity: 0.8; " />
          </draggable-grade>
        </v-carousel>
        <div class="elevation-2">
          <v-row class="px-6 py-3" v-if="activePhoto">
            {{ activePhotoTitle || "" }}
          </v-row>
          <v-divider />
          <v-row class="justify-center align-center px-2">
            <v-col cols="3" v-for="(photo, j) in photos" :key="j">
              <v-img :src="photo.src" height="60" contain @click="carousel = j" style="cursor: pointer"
                :class="[{ 'actived': carousel == j }]">
              </v-img>
            </v-col>
          </v-row>
        </div>
        <div class="mt-2 body-2" style="text-align: center; padding-top: 10px;">Oculte a grade para ampliar ou reduzir a
          imagem.
        </div>
      </v-col>
    </v-row>
    <v-row class="px-3 pt-6" v-if="!isStudent">
      <v-row style="width:100%" class="px-3">
        <span>Resultados</span>
      </v-row>
      <v-row style="width:100%" class="px-3">
        <v-divider></v-divider>
      </v-row>
      <v-row style="width: 100%" class="justify-center align-center">
        <v-col xs="12" sm="6" lg="4" v-if="result">
          <v-card elevation-2>
            <v-row class="px-3 py-3 justify-center align-center">
              <v-img contain color="lightgrey" :lazy-src="result.preview" :src="result.full" />
            </v-row>
            <v-row class="px-3 py-3 justify-center align-center">
              <span class="title">{{ result.name }}</span>
            </v-row>
            <v-row class="px-3 pb-6 justify-center align-center">
              <span class="body-2">{{ result.description }}</span>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="!readonly">
      <v-col class="d-flex justify-end">
        <v-btn text color="secondary" @click="$emit('closeDialog')">
          Cancelar
        </v-btn>
        <v-btn class="ml-10" color="primary" @click="cycle === 'micro' ? $emit('updateMicrocycleEvaluation', 'close') : $emit('updateMesocycleEvaluation', 'close')">
          Salvar Avaliação
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DraggableGrade from '@/components/base/DraggableGrade'
import PreviewUploadPhotoCard from '@/components/student/show/studentEvaluation/PreviewUploadPhotoCard'
import posturaEvaluationFields from '@/mixins/posturaEvaluationFields'
import posturaUploadFilesList from '@/mixins/posturaUploadFilesList'

import { forIn } from 'lodash'

export default {
  name: 'postura',
  components: {
    DraggableGrade,
    PreviewUploadPhotoCard
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    isStudent: {
      default: false,
      type: Boolean
    },
    cycle: {
      type: String,
      default: 'meso'
    }
  },
  computed: {
    ...mapGetters('students', ['student', 'mesocycleToBeEvaluated']),
    getPhotoSrc () {
      return (key, name) => {
        let value = this.mesocycleToBeEvaluated.photos[key].find(item => item.name === name)
        return value === undefined ? '' : value.src
      }
    },
    activePhoto () {
      if (this.photos && this.photos.length > 0) {
        return this.photos[this.carousel]
      }
      return null
    },
    activePhotoTitle () {
      if (this.photos && this.photos.length > 0) {
        if (this.photoSelector === 0) {
          return this.files.principais[this.activePhoto.name] || ''
        } else {
          return this.files.opcionais[this.activePhoto.name] || ''
        }
      }
      return null
    },
    photos () {
      if (this.photoSelector === 0) {
        return this.mesocycleToBeEvaluated.photos.principais
      } else {
        return this.mesocycleToBeEvaluated.photos.opcionais
      }
    },
    result () {
      return this.getSelected(this.mesocycleToBeEvaluated.evaluated_items.posture)
    },
    disableInput () {
      return this.readonly || this.isStudent
    }
  },
  data: () => ({
    items: null,
    files: posturaUploadFilesList,
    photoSelector: 0,
    carousel: null,
    dialogPhotos: false,
    visible: false,
    postura: ''
  }),
  mounted () {
    this.items = JSON.parse(JSON.stringify(posturaEvaluationFields))
    this.loadItems()
  },
  methods: {
    ...mapActions('base', ['setLoader', 'setSnackbar']),
    ...mapActions('students', ['updateMesocycleToBeEvaluated']),
    updateItems () {
      var postures = []
      forIn(this.items, (value, key) => {
        value.forEach(side => {
          side.forEach(item => {
            postures.push({ key: item.key, selected: item.selected })
          })
        })
      })

      this.mesocycleToBeEvaluated.evaluated_items.posture = postures
    },
    loadItems () {
      this.getSelected(this.mesocycleToBeEvaluated.evaluated_items.posture)
      this.items = forIn(this.items, (value, key) => {
        value.forEach(side => {
          side.forEach(item => {
            try {
              this.mesocycleToBeEvaluated.evaluated_items.posture.map((itemSaved) => {
                if (item.key === itemSaved.key) {
                  item.selected = itemSaved.selected
                }
              })
            } catch { }
          })
        })
      })
    },

    getSelected (list = []) {
      if (!Array.isArray(list)) {
        return
      }

      const aHiperlordotica = ['RETIFICAÇÃO TORÁCICA']
      const aHiperlordotica2 = ['HIPERLORDOSE LOMBAR']
      if (list.some(item => aHiperlordotica.includes(item.selected))) {
        if (list.some(item => aHiperlordotica2.includes(item.selected))) {
          return {
            'name': 'Postura',
            'description': 'Hiperlordotica',
            'preview': require('@/assets/images/postura/preview/perfil_hiperlordotico.jpg'),
            'full': require('@/assets/images/postura/full/perfil_hiperlordotico.jpg')
          }
        }
      }

      const aCifotico = ['HIPERCIFOSE TORÁCICA SUPERIOR',
        'HIPERCIFOSE TORÁCICA INFERIOR',
        'HIPERCIFOSE TORÁCICA LONGA',
        'HIPERCIFOSE TORÁCICA']

      const aHiperlordotica3 = ['HIPERLORDOSE COMPENSATÓRIA']
      if (list.some(item => aCifotico.includes(item.selected))) {
        if (list.some(item => aHiperlordotica2.includes(item.selected))) {
          return {
            name: 'Postura',
            description: 'Cifótico-Lordótico',
            preview: require('@/assets/images/postura/preview/perfil_cifotico-lordotico.jpg'),
            full: require('@/assets/images/postura/full/perfil_cifotico-lordotico.jpg')
          }
        }
        if (list.some(item => aHiperlordotica3.includes(item.selected))) {
          return {
            name: 'Postura',
            description: 'Sway-Back',
            preview: require('@/assets/images/postura/preview/perfil_sway-back.jpg'),
            full: require('@/assets/images/postura/full/perfil_sway-back.jpg')
          }
        }
      }

      const aCifoticoN = ['HIPERCIFOSE TORÁCICA SUPERIOR',
        'HIPERCIFOSE TORÁCICA INFERIOR',
        'HIPERCIFOSE TORÁCICA LONGA']

      const aCifoticoN2 = ['RETIFICAÇÃO LOMBAR', 'CIFOSE NA LOMBAR']
      if (list.some(item => aCifoticoN.includes(item.selected))) {
        if (list.some(item => aCifoticoN2.includes(item.selected))) {
          return {
            name: 'Postura',
            description: 'Dorso plano',
            preview: require('@/assets/images/postura/preview/perfil_dorso_plano.jpg'),
            full: require('@/assets/images/postura/full/perfil_dorso_plano.jpg')
          }
        }
      }
      const posturaResults =
      {
        'name': 'Postura',
        'description': 'Postura Normal',
        'preview': require('@/assets/images/postura/preview/perfil_normal.jpg'),
        'full': require('@/assets/images/postura/full/perfil_normal.jpg')
      }

      return posturaResults
    },
    zoomToggler (zoom) {
      let ref
      try {
        if (this.visible) {
          ref = this.$refs.zoomG
        } else {
          ref = this.$refs[`zoom${this.carousel}`][0]
        }
        ref.onZoom({ deltaZoom: zoom })
        return 1
      } catch (e) {
        return 0
      }
    }
  },
  watch: {
    items: {
      handler () {
        this.updateItems()
      }
    }
  }
}
</script>

<style>
.actived {
  border: 1px solid black;
}

.img-new {
  background-image: url("../../../../assets/images/postura/simetrograph.png");
  width: 100%;
  background-position: 52.7% 54.3%;
}
</style>
